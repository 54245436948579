 <template>
    <div class="edit-goods" style="margin:20px 20px;">
        <v-card style="min-width:890px;" flat>
            <v-card-title>
                <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                    {{message}}
                </v-alert>
                <span class="text-h8" style="font-weight:600">编辑商品信息</span>
                <router-link :to="'/goods'" style="color:#f57c00;font-size:16px;margin-left:30px">返回商品列表</router-link>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row >
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        style="padding:0">
                            <v-form
                            ref="infoForm"
                            v-model="valid"
                            lazy-validation
                            >
                                <v-col
                                cols="12"
                                sm="6"
                                md="12">
                                    <div class="normal-form" style="padding:15px 0 0">
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="12">
                                        <div style="font-size:20px;font-weight:600">编辑分类</div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            >
                                            <v-select
                                            :items="parentName"
                                            v-model="parenthashName"
                                            label="一级分类"
                                            outlined
                                            class="mx-auto col-12"
                                            no-data-text="暂无一级分类"
                                            :rules="[v => !!v || '一级分类不能为空']"
                                            @change="choseParent(parenthashName)"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            >
                                            <v-select
                                            :items="sonName"
                                            v-model="sonhashName"
                                            :rules="[v => !!sonhashName[0] || '二级分类不能为空']"
                                            outlined
                                            class="mx-auto col-12"
                                            no-data-text="暂无二级分类"
                                            label="二级分类"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="12">
                                        <div style="font-size:20px;font-weight:600">商品名称</div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="12"
                                            >
                                            <v-text-field 
                                            v-model="goodsName" 
                                            class="mx-auto col-12" 
                                            label="商品名称"  
                                            :rules="[v => !!v || '商品名称不能为空']"
                                            require />
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="12">
                                        <div style="font-size:20px;font-weight:600">权限</div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="12"
                                            >
                                            <v-radio-group
                                                v-model="isprivate"
                                                row
                                            >
                                                <v-radio
                                                label="公开"
                                                value="0"
                                                ></v-radio>
                                                <v-radio
                                                label="私有"
                                                value="1"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </div>
                                </v-col>
                            </v-form>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="padding:0"
                        >
                        <div>
                            <v-col
                            cols="12"
                            sm="6"
                            md="12">
                                <div class="normal-norms specvalue-content">
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="12">
                                        <div style="font-size:20px;font-weight:600">规格类型</div>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                                    style="padding-bottom:0"
                                    >
                                        <v-radio-group
                                        v-model="spectype"
                                        @click="spectypeType"
                                        row
                                        >
                                            <v-radio
                                            label="单规格"
                                            value="0"
                                            ></v-radio>
                                            <v-radio
                                            label="多规格"
                                            value="1"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </div>
                            </v-col>
                            <v-col 
                            v-if="spectype==0"
                            cols="12"
                            sm="6"
                            md="12"
                            >
                                <v-col
                                cols="12"
                                sm="6"
                                md="12"
                                >
                                    <div style="font-size:20px;font-weight:600">单规格值添加</div>
                                </v-col>
                                <v-form
                                ref="normsForm"
                                v-model="valid"
                                lazy-validation
                                >
                                    <v-row  align-content="center">
                                        <v-col 
                                            cols="12"
                                            sm="6"
                                            md="3"
                                        > 
                                                <v-text-field 
                                                v-model="price1" 
                                                class="mx-auto col-12" 
                                                label="商品价格:"
                                                :rules="[v => !!v || '商品价格不能为空']"
                                                oninput="value=value.replace(/[^\d]/g,'')"
                                                require />
                                        </v-col>
                                    
                                        <v-col 
                                            cols="12"
                                            sm="6"
                                            md="3"
                                        >
                                            <v-text-field 
                                            v-model="unit1" 
                                            class="mx-auto col-12" 
                                            label="单位:"  
                                            :rules="[v => !!v || '单位不能为空']"
                                            require />
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            sm="6"
                                            md="6"
                                        >
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        >
                                            <v-file-input
                                            v-model="fileInfo"
                                            required
                                            show-size accept="image/png, image/jpeg, image/bmp"
                                            @change="uploadImage"
                                            label="点击上传商品图片">
                                            </v-file-input>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        >
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        style="display:flex;justify-content:center;align-items:flex-start"
                                        >
                                        <img :src="image" alt="" class="image" width="50%">
                                        <!-- <img v-if="image" src="@/assets/1.jpg" class="image" height="100px"> -->
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                            <v-col 
                            cols="12"
                            sm="6"
                            md="12"
                            v-else>
                                <div class="norms-position">
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                                    style="padding-top:0">
                                        <div style="font-size:20px;font-weight:600">多规格值添加</div>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="6">
                                        <div class="norms-content norms-position">
                                            <v-col 
                                            cols="12"
                                            sm="6"
                                            md="6">
                                                <v-text-field 
                                                v-model="specname" 
                                                class="mx-auto col-12" 
                                                label="规格名:" 
                                                @keyup.enter.prevent="getSpecData" 
                                                require />
                                            </v-col>

                                            <v-col 
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            >
                                                <v-text-field 
                                                v-model="specvalue"
                                                class="mx-auto col-12" 
                                                label="规格值:"  
                                                @keyup.enter.prevent="getSpecData"
                                                require />
                                            </v-col>
                                            <v-col 
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            >
                                            <v-btn
                                            depressed
                                            color="primary"
                                            @click="getSpecData"
                                            style="margin-left:10px"
                                            >
                                            添加
                                            </v-btn>
                                            </v-col>
                                        </div>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="6">
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="6">
                                    <div v-if="specDataList[0]">
                                        <div v-for="(item,index)  in specDataList" :key="index" class="norms-content" style="margin-bottom:5px">
                                            <div style="margin-left:10px">
                                                <v-chip
                                                class="ma-2"
                                                color="primary"
                                                outlined
                                                label
                                                >
                                                {{item.key}}&nbsp; 
                                                <v-icon
                                                small
                                                @click="deleteKey(item,index)"
                                                >
                                                mdi-close
                                                </v-icon>
                                                </v-chip>
                                            </div>
                                            <div style="margin-left:10px">
                                                <v-chip
                                                v-for="(item2,index2)  in item.value" :key="index2"
                                                class="ma-2"
                                                color="green"
                                                outlined
                                                >
                                                {{item2}}
                                                &nbsp; 
                                                    <v-icon
                                                    small
                                                    @click="deleteValue(item,index2)"
                                                    >
                                                    mdi-close
                                                    </v-icon>
                                                </v-chip>
                                            <div class="specvalue-content">
                                                <v-col
                                                sm="4"
                                                >
                                                <v-text-field
                                                    v-model="addSpecvalue[index]" 
                                                    label="规格值"
                                                    @keyup.enter.prevent="addSpecvalueData(item.key,item.specnamehash,addSpecvalue[index])"
                                                    require
                                                ></v-text-field>
                                                </v-col>
                                                <v-col
                                                sm="2"
                                                >
                                                    <v-btn
                                                    class="mx-2"
                                                    color="primary"
                                                    @click="addSpecvalueData(item.key,item.specnamehash,addSpecvalue[index])"
                                                    >
                                                    添加
                                                    </v-btn>
                                                </v-col>
                                            </div>
                                            </div>
                                        </div>
                                    </div>    
                                    </v-col>
                                </div>
                               
                            </v-col> 
                        </div>
                        
                        </v-col> 
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="margin: 0 30px 0 10px"
                        v-if="baseList[0]&&spectype==1"
                        >
                             <div>
                                    <v-data-table
                                    :headers="headers"
                                    :items="baseList"
                                    :server-items-length="itemsLength"
                                    hide-default-footer
                                    class="elevation-1"
                                     no-data-text="暂无数据"
                                    style="border:1px solid #ccc"
                                    >
                                    <template v-slot:[`header.actions`]="{ header }">
                                        <div class="actions">
                                        <div>{{header.text}}</div>
                                            <v-btn v-if="recoves.length" color="primary" @click="recove">
                                                恢复规格
                                            </v-btn>
                                            <v-btn v-else color="primary" @click="recove" disabled>
                                                恢复规格
                                            </v-btn>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.actions`]={item,index} >
                                        <div class="specvalue-content" ref="spech">
                                        <div
                                        class="add-sku"
                                        >
                                            <v-text-field 
                                            v-model="item.price"
                                            class="col-6" 
                                            label="价格:" 
                                            @focus="isFocusPrice(index)"
                                            @blur="isBlurPrice(index)"
                                            oninput="value=value.replace(/[^\d]/g,'')" 
                                            require />
                                            <v-btn v-if="item.focusPrice" color="primary" @mousedown="addAllPrice(index,item.price)">一键添加</v-btn>
                                        </div>
                                        <div
                                        class="add-sku"
                                        >
                                            <v-text-field 
                                            v-model="item.unit"
                                            class="col-6" 
                                            label="单位:" 
                                            @focus="isFocusUnit(index)"
                                            @blur="isBlurUnit(index)" 
                                            require />
                                                <v-btn v-if="item.focusUnit" color="primary" @mousedown="addAllUnit(index,item.unit)">一键添加</v-btn> 
                                        </div>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="3"
                                        >
                                            <v-file-input
                                            v-model="item.fileInfo"
                                            required
                                            show-size accept="image/png, image/jpeg, image/bmp"
                                            @change="uploadImages(item.id,item.fileInfo)"
                                            label="点击上传商品图片">
                                            </v-file-input>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="2"
                                        >
                                            <img :src="item.image" alt="" height="100px">
                                            <!-- <img v-if="item.image" src="@/assets/1.jpg" height="100px"> -->
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="1"
                                            >
                                            <v-btn color="primary" @click="deleteItemSku(item,index)">删除</v-btn>
                                        </v-col>
                                        </div>
                                    </template>
                                    </v-data-table>
                                </div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            v-if="shopsList[0]">
                                <div class="normal-form" style="padding:15px 0 0">
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    md="12">
                                    <div style="font-size:20px;font-weight:600;">下列报价小店中含有该商品，是否进行规格同步</div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="12">
                                    <div v-for="(item,index) in shopsList" :key="index">
                                            <v-checkbox
                                            v-model="shoplist"
                                            :label="item.shopname"
                                            :value="item.shophash"
                                            ></v-checkbox>
                                    </div>
                                    </v-col>
                                </div>
                            </v-col>
                   </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions style="padding-left:30px">
                
                <v-btn
                class="mx-2"
                color="primary"
                @click="editShops"
                >
                确定修改商品信息
                </v-btn>
                    <v-btn
                class="mx-2"
                color="primary"
                @click="unShops"
                >
                取消
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            <div style="height:20px"></div>
         </v-card>
    </div>
</template>
<script>
import {specAdd,specAddValue,editGoods,imageUpload,parentList,childList} from '@/api/goods.js'
export default {
    data(){
        return{
            valid:true,
            chip:true,
            goodsName:'',
            itemsLength:99,
            messageError:false,
            message:'',
            parentData:[],
            parentName:[],
            parenthashName:'',
            categoryparent:'',
            sonData:[],
            sonName:[],
            sonhashName:[],
            categoryName:'',
            categoryhash:'',
            fileInfo:null,
            price1:null,
            unit1:'',
            spechashs:'',
            isprivate:"",
            spectype:"0",
            preSpecname:[],
            specname:'',
            specnameList:[],
            specDataList:[],
            specvalue:'',
            specvalueList:[],
            addSpecvalue:[],
            specnamehash:'',
            specvaluehash:'',
            specvaluehashList:[],
            dataList:[],
            skuList:[],
            image:'',
            objListData:[],
            desserts:[],
            baseList:[],
            specList:[],
            detailsData:{},
            hash:'',
            sku:[],
            shopsList:[],
            shoplist:[],
            copyBaseList:[],
            copySpecDataList:[],
            recoves:[]
        }
    },
    computed:{
        headers () {
            var dataKeys = this.specDataList.map(item=>{
                    return item.key
            })
            var tableList=[];
            for(let i = 0;i<dataKeys.length;i++){
                var data = {
                    text:dataKeys[i],
                    sortable: false,
                    value:dataKeys[i]
                }
                tableList.push(data)
            }
            var actions = {text: '操作',sortable: false, value: 'actions'}
            tableList.push(actions)
            return tableList
      }
    },
    watch:{
        parenthashName(val){
            this.parenthashName = val;
        },
        categoryName(val){
            // console.log(val);
            this.parenthashName = val;
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this.parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            // console.log(parenthash[0]);
        },
        goodsName(val){
            this.goodsName = val;
        },
        sonhashName(val){
            // console.log(val);
            this.sonhashName = val;
            const sonhash = this.sonData.filter(item=>{
                if(item.name === this.sonhashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            this.categoryhash = sonhash[0]
            // console.log(sonhash[0]);
        }
        
    },
    
    mounted(){
        this.detailsData = this.$route.params;
        // console.log( this.detailsData);
        this.getParentsList();
        this.$nextTick(()=>{
            this.getData(this.detailsData);
        })
    },
    methods:{
        getData(res){
            console.log(res);
            this.goodsName = res.name
            this.categoryhash = res.categoryhash
            this.category = res.category
            this.hash = res.hash;
            this.categoryparent = res.categoryparent;
            this.shopsList= res.shops;
            // console.log(this.shopsList);
            if(res.private){
                this.isprivate = "1"
            }else{
                 this.isprivate = "0"
            }
            if(res.spec==""){
                this.spectype = "0"
                this.price1 = res.sku[0].price/100
                this.unit1 = res.sku[0].unit
                this.image = res.sku[0].image
            }else{
                this.spectype = "1"
                const specname = res.spec.map((item)=>{
                      return item.specname;
                 })
                 this.preSpecname = specname;
                 const specvalue = res.spec.map((item)=>{
                      return item.specval
                 })
                 const specvalueDataList= [];
                 for(let i = 0;i<specvalue.length;i++){
                     var data= [],
                     data = specvalue[i].map(item=>{
                         return item.specvalname
                     })
                     specvalueDataList.push(data)
                 }
                //  console.log(specvalueDataList);
                const specvaluehashDataList= [];
                 for(let i = 0;i<specvalue.length;i++){
                     var data2= [],
                     data2 = specvalue[i].map(item=>{
                         return item.specvalhash
                     })
                     specvaluehashDataList.push(data2)
                 }
                //  console.log(specvaluehashDataList);
                //  console.log(specvalueDataList);
                 for(let i = 0 ;i<res.spec.length;i++){
                     var data2 = {}
                      data2 = {
                        key:specname[i],
                        value:specvalueDataList[i],
                        specnamehash:res.spec[i].spechash,
                        specvaluehash:specvaluehashDataList[i]
                    }
                    this.specDataList.push(data2)
                 }
                 this.sku = res.sku
                //  console.log(this.sku);
                // // console.log(this.specDataList);
                this.copySpecDataList = JSON.parse(JSON.stringify(this.specDataList));
                this.dataList = this.specDataList.map(item=>{
                    return item.value
                })
                this.skuList = this.combine( ...this.dataList)
                    //specnamehash
                var spec = this.specDataList.map(item=>{
                        return item.specvaluehash
                    })
                this.specList= this.combine( ...spec)
                var dataKeys = this.specDataList.map(item=>{
                    return item.key
                })
                // console.log(dataKeys);
                this.skuList = this.combine( ...this.dataList)
                // console.log(this.skuList);
                // console.log(this.sku[1].spechashs);
                const baselist = [];
                for(let i = 0 ;i<this.skuList.length;i++){
                    var baseData = this.changeObj(dataKeys,this.skuList[i])
                    var baseDatas = {
                        ...baseData,
                        skuhash:this.specList[i].toString(),
                    }
                   baselist.push(baseDatas);
                    
                };
                for(let item1 of baselist) {
                    for(let item2 of this.sku) {
                        if(item1.skuhash == item2.spechashs) {
                            var baseDatas = {
                                ...item1,
                                price:item2.price/100||null,
                                unit:item2.unit||'',
                                image:item2.image||null,
                                fileInfo:null, 
                                focusPrice:false,
                                focusUnit:false
                            }
                            this.baseList.push(baseDatas)
                        }
                    }
                }
                // console.log(this.baseList);
                this.copyBaseList =JSON.parse(JSON.stringify(this.baseList))
            }
        },
        // 复原
        recove(){
            console.log(111);
            if(this.recoves.length<=0){
                return false
            }
            this.baseList.splice(this.recoves[0].index,0,this.recoves[0]);
            this.recoves.splice(0,1);
            console.log(this.recoves);
        },
        // 规格发生变化触发事件
         specDataLists(){
            //value
            this.dataList = this.specDataList.map(item=>{
                return item.value
            })
           this.skuList = this.combine( ...this.dataList)
           //specnamehash
            var spec = this.specDataList.map(item=>{
                return item.specvaluehash
            })
            this.specList= this.combine( ...spec)
            // console.log(this.specList);
            var dataKeys = this.specDataList.map(item=>{
                return item.key
            })
            
            this.skuList = this.combine( ...this.dataList)
            this.baseList = [];
            for(let i = 0 ;i<this.skuList.length;i++){
                var baseData = this.changeObj(dataKeys,this.skuList[i])
                var baseDatas = {
                    ...baseData,
                    skuhash:this.specList[i].toString(),
                    id:i,
                    price:this.sku[i].price/100,
                    unit:this.sku[i].unit,
                    image:this.sku[i].image,
                    fileInfo:null,
                    focusPrice:false,
                    focusUnit:false
                }
                // console.log(baseDatas);
                this.baseList.push(baseDatas);
            }
            // console.log(this.baseList);
                // console.log(baseData);
        },
         // 获取父类
        getParentsList(){
            parentList().then(res=>{
                // console.log(res);
                this.parentData = res.data.data.list
                this.parentName = this.parentData.map(item=>{
                    return item.name
                })
                const data = this.parentData.filter(item=>{
                    if(item.name === this.categoryparent){
                        return item
                    }
                }).map(item=>{
                    return item.hash
                })
                
                // console.log(data[0]);
                 this.parenthashName =  this.categoryparent;
                 childList(data[0]).then(res=>{
                    //  console.log(res);
                    this.sonData = res.data.data.list
                    this.sonName = this.sonData.map(item=>{
                    return item.name
                })
                this.sonhashName = this.category
            })
                // this.parenthashName = data[0]
            })
        },
        // 选择子类
         choseParent(pHash){
            // console.log(pHash);
            this.parenthashName = pHash;
            this.categoryhash = '';
            this.sonhashName = [];
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            childList(parenthash[0]).then(res=>{
                this.sonData = res.data.data.list
                this.sonName = this.sonData.map(item=>{
                    return item.name
                })
            })
        },
        // 删除
        deleteKey(item,index){
            // console.log(item,index);
            // this.array.splice(index, 1);
            if(this.specDataList.length===1){
                this.$nextTick(()=>{
                    this.message = "只剩一个了,无法删除";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false
            }
            this.specDataList.splice(index, 1);
            this.preSpecname  = this.specDataList.map(item=>{
                return item.key
            })
            this.specDataLists();
        },
        // 删除规格值
        deleteValue(parentItem,index){
            // console.log(parentItem,index);
            var data = this.specDataList.filter(item=>{
                if(item === parentItem){
                    return item
                }
            })
            // console.log(data);
            data[0].value.splice(index, 1);
            data[0].specvaluehash.splice(index, 1);
            // console.log(this.specDataList);
            this.dataList = this.specDataList.map(item=>{
                    return item.value
                })
            this.skuList = this.combine( ...this.dataList)
                //specnamehash
            var spec = this.specDataList.map(item=>{
                    return item.specvaluehash
                })
            this.specList= this.combine( ...spec)
            var dataKeys = this.specDataList.map(item=>{
                return item.key
            })
            this.baseList = [];
            for(let i = 0 ;i<this.skuList.length;i++){
                var baseData = this.changeObj(dataKeys,this.skuList[i])
                var baseDatas = {
                    ...baseData,
                    skuhash:this.specList[i].toString(),
                    id:i,
                    price:null,
                    unit:'',
                    image:'',
                    fileInfo:null,
                    focusPrice:false,
                    focusUnit:false
                }
                this.baseList.push(baseDatas);
            };
            // console.log(this.baseList); 
        },
        spectypeType(){
            // console.log(this.spectype);
        },
        // 添加规格和规格值
        getSpecData(){
             if (!this.specvalue||!this.specname ) {
                this.$nextTick(()=>{
                    this.message = "添加规格与规格值不能为空";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false;
            }
            // 判断添加的规格值是否重复
            var params = {
                specname:this.specname,
                specvalue:this.specvalue
            }
            // console.log(params);
            specAdd(params).then(res=>{
                this.specnamehash = res.data.data.specnamehash;
                this.specvaluehash = res.data.data.specvaluehash;
                this.specvaluehashList.push(this.specvaluehash)
                var specData = {
                    key: this.specname,
                    value:this.specvalueList,
                    specnamehash:this.specnamehash,
                    specvaluehash:this.specvaluehashList
                }
                // 判断规格名是否重复
                const opt = this.preSpecname.filter(item=>{
                    if(item === this.specname){
                        return item
                    }else{
                        return false
                    }
                })
                // console.log(opt);
                if(opt[0]){
                    this.$nextTick(()=>{
                        this.message = "添加规格名已重复，请重新添加";
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    this.specvalueList = [];
                    this.specvaluehashList =[];
                    return false
                }
                this.preSpecname.push(this.specname); 
                this.specDataList.push(specData);
                this.specname = '';
                this.specvalue = '';
                this.specvalueList = [];
                this.specvaluehashList =[]
                // console.log(this.specDataList);
                 this.dataList = this.specDataList.map(item=>{
                return item.value
            })
            
           this.skuList = this.combine( ...this.dataList)
           //specnamehash
            var spec = this.specDataList.map(item=>{
                return item.specvaluehash
            })
            this.specList= this.combine( ...spec)
            // console.log(this.specList);
            var dataKeys = this.specDataList.map(item=>{
                return item.key
            })
            
            this.skuList = this.combine( ...this.dataList)
            this.baseList = [];
            for(let i = 0 ;i<this.skuList.length;i++){
                var baseData = this.changeObj(dataKeys,this.skuList[i])
                var baseDatas = {
                    ...baseData,
                    skuhash:this.specList[i].toString(),
                    id:i,
                    price:this.sku[i].price/100,
                    unit:this.sku[i].unit,
                    image:this.sku[i].image,
                    fileInfo:null,
                    focusPrice:false,
                    focusUnit:false
                }
                // console.log(baseDatas);
                this.baseList.push(baseDatas);
            }
            })
        },
        // 添加规格值
        addSpecvalueData(key,hash,value){
            // console.log(value);
            // console.log(hash);
            if (!value) {
                this.$nextTick(()=>{
                    this.message = "添加规格值不能为空";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false;
            }
             // 判断添加的规格值是否重复
            // console.log(this.dataList);
            // console.log(value);
            for(let i = 0; i<this.dataList.length;i++){
                const opt = this.dataList[i].filter(item=>{
                    if(item===value){
                        return item
                    }else{
                        return false
                    }
                })
                // console.log(opt);
                if(opt[0]){
                    this.$nextTick(()=>{
                        this.message = "添加规格值已重复，请重新添加";
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false
                } 
            }
            var params = {
                specnamehash:hash,
                specvalue:value
            }
            specAddValue(params).then(res=>{
                // console.log(res);
                this.specvaluehash = res.data.data.specvaluehash
                // console.log(this.specvaluehash);
                var data= this.specDataList.filter(item=>{
                    if(item.key === key){
                        return item
                    }
                })
                data[0].value.push(value);
                data[0].specvaluehash.push( this.specvaluehash)
                
                this.dataList = this.specDataList.map(item=>{
                    return item.value
                })
                this.skuList = this.combine( ...this.dataList)
                //specnamehash
                var spec = this.specDataList.map(item=>{
                    return item.specvaluehash
                })
                this.specList= this.combine( ...spec)
                // console.log(this.specList);
                // console.log(this.specDataList);
                var dataKeys = this.specDataList.map(item=>{
                    return item.key
                })
                this.baseList = [];
                for(let i = 0 ;i<this.skuList.length;i++){
                    var baseData = this.changeObj(dataKeys,this.skuList[i])
                    var baseDatas = {
                        ...baseData,
                        skuhash:this.specList[i].toString(),
                        id:i,
                        price:null,
                        unit:'',
                        image:'',
                        fileInfo:null,
                        focusPrice:false,
                        focusUnit:false
                    }
                    this.baseList.push(baseDatas);
                }
                // console.log(dataKeys);
                // console.log(baseData);
                // console.log(this.baseList);
                this.addSpecvalue = [];
            })
        },
        uploadImage(){
            // console.log(this.fileInfo);
            if(this.fileInfo){
                var formData = new FormData();
                formData.append("file", this.fileInfo); 
                imageUpload('goods',formData).then(res=>{
                    this.image = res.data.data.image;
                    this.$set(this.image,res.data.data.image)
                })
            }else{
                this.image = '';
            }
        },
        uploadImages(id,fileInfo){
            if(fileInfo){
                var formData = new FormData();
                formData.append("file",fileInfo); 
                imageUpload('goods',formData).then(res=>{
                    this.image = res.data.data.image;
                        var data = this.baseList.filter(item=>{
                            if(item.id === id)
                            return item;
                        })
                        // console.log(data[0]);
                        data[0].image = this.image;
                })
            }else{
                this.baseList[id].image = '';
            }
        },
        // 删除规格组合
        deleteItemSku(item,index){
            let append = {
                index,
                ...item
            }
            this.recoves.unshift(append)
            console.log(this.recoves);
            this.baseList.splice(index,1);
            const b = [];//baseList中存在的规格值遍历
            for(let i = 0; i<this.baseList.length;i++){
                for(let j = 0 ;j<this.specDataList.length;j++){
                    let a  = Object.values(this.baseList[i])[j];
                    // console.log(a);
                    b.push(a);
                }
            }
            const c= [];//specDataList找出所有规格值
            this.specDataList.map(item=>{
                c.push(...item.value)
            })
            // 进行比较，找出不存在的规格值
            const _arr1 =b.filter(item1 => !c.includes(item1));
            const _arr2 =c.filter(item2 => !b.includes(item2));
            const _arr =_arr1.concat(_arr2)
            // console.log(_arr);
            //进行删除
            if(_arr.length>=1){
                 this.recoves = [];
            }
            if(_arr.length>1){
                for(let i= 0;i<_arr.length;i++){
                    this.specDataList.filter((item)=>{
                        item.value.filter((item1,index)=>{
                            if(item1.includes(_arr[i])){
                                if(item.value.length===1){
                                    item.value.splice(index,1)
                                    item.specvaluehash.splice(index,1) 
                                    this.specDataList.splice(0,this.specDataList.length);
                                    this.preSpecname  = this.specDataList.map(item=>{
                                        return item.key
                                    })
                                    return
                                }
                                item.value.splice(index,1)
                                item.specvaluehash.splice(index,1)
                            }
                        })
                    })
                }
                return
            }
            this.specDataList.filter((item)=>{
                item.value.filter((item1,index)=>{
                    if(item1.includes(..._arr)){
                        if(item.value.length===1){
                            item.value.splice(index,1)
                            item.specvaluehash.splice(index,1) 
                            this.specDataList.splice(0,this.specDataList.length);
                            this.preSpecname  = this.specDataList.map(item=>{
                                return item.key
                            })
                            return
                        }
                        item.value.splice(index,1)
                        item.specvaluehash.splice(index,1)
                    }
                })
            })
            if(_arr.length>1){
                for(let i = 0; i<_arr.length;i++){
                    this.specDataList[i].value.splice(index,1)
                    this.specDataList[i].specvaluehash.splice(index,1)
                }
            }
        },
        // 判断是否失去焦点
        // 获取焦点
        isFocusPrice(index){
            this.baseList[index].focusPrice = true
        },
        // 失去焦点
        isBlurPrice(index){
            this.baseList[index].focusPrice = false
        },
        // 获取焦点
        isFocusUnit(index){
            this.baseList[index].focusUnit = true
        },
        // 失去焦点
        isBlurUnit(index){
            this.baseList[index].focusUnit = false
        },
        // 一键添加价格
        addAllPrice(index,price){
            for(let i= index+1;i<this.baseList.length;i++){
                this.baseList[i].price = price
            }
        },
        // 一键添加单位
        addAllUnit(index,unit){
            for(let i= index+1;i<this.baseList.length;i++){
                this.baseList[i].unit = unit
            }
        },
        // 编辑添加
        editShops(){
            // console.log(this.price1,this.unit1);
            if(!this.goodsName||!this.categoryhash){
                this.$refs.infoForm.validate()
                this.$nextTick(()=>{
                        this.message = "商品信息添加尚未完成，请完成后才能添加商品";
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false;
            }
            var spectype = -1;
            if(this.spectype==="0"){
                 spectype = 0;
                 if (!this.unit1 || !this.price1) {
                    this.$refs.normsForm.validate()
                    this.$nextTick(()=>{
                        this.message = "规格信息尚未完成，请完成后才能添加";
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false;
                 }
                 this.sku = [
                    {
                        unit:this.unit1,
                        skuhash:'',
                        price:this.price1,
                        image:this.image
                    }
                ]
            }
            if(this.spectype==="1"){
                spectype = 1;
                this.sku =  this.baseList.map(({price, skuhash,unit,image}) => ({ price, skuhash,unit,image}))
                const sure = this.sku.filter(item=>{
                    if(item.price===''){
                        return true
                    }
                })
                const sure1 = this.sku.filter(item=>{
                    if(item.unit===''){
                        return true
                    }
                })
                if(sure[0]){
                    this.$nextTick(()=>{
                        this.message = '商品价格尚未填写完全';
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false
                }
                // console.log(sure);
                if(sure1[0]){
                    this.$nextTick(()=>{
                        this.message = '商品单位尚未填写完全';
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false
                }
           }
            var isprivate = -1;
            if(this.isprivate==="0"){
                 isprivate = 0;
            }
            if(this.isprivate==="1"){
                spectype = 1;
                
            }
            // console.log(this.shoplist.toString());
            var params = {
                hash:this.hash,
                categoryhash:this.categoryhash,
                spectype:spectype,
                name:this.goodsName,
                shophash:this.shoplist.toString(),
                isprivate:isprivate,
                sku:this.sku,
            }
            editGoods(params).then(res=>{
                // console.log(res);
                 if(res.data.code===200){
                    this.$router.push('/goods');
                }else{
                        this.$nextTick(()=>{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
        unShops(){
           this.$router.push('/goods');
        },
        // sku全排列
        combine(...chunks) {
            let res = []
            let helper = function (chunkIndex, prev) {
                let chunk = chunks[chunkIndex]
                let isLast = chunkIndex === chunks.length - 1
                for (let val of chunk) {
                    let cur = prev.concat(val)
                    if (isLast) {
                        // 如果已经处理到数组的最后一项了 则把拼接的结果放入返回值中
                        res.push(cur)
                    } else {
                        helper(chunkIndex + 1, cur)
                    }
                }
            }
            // 从属性数组下标为 0 开始处理
            // 并且此时的 prev 是个空数组
            helper(0, [])
            return res
        },
        changeObj(list,values){
            var result={};
            for(var i=0;i<list.length;i++){
                    if(values){
                        result[list[i]]=values[i];
                    }else{
                        result[list[i][0]]=list[i][1];
                    }
            }
            return result;  
        } 
    }
}
</script>
<style scoped>
.specvalue-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.v-alert__content {
    flex: 1 1 auto;
    min-width: 200px;
}
.specvalue-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.normal-norms{
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.normal-form{
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.norms-content{
    border-radius: 5px;
    border:1px solid #00000099;
}
.norms-position{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.add-sku{
    width: 250px;
    display:flex;
    justify-content: flex-start;
    align-items:flex-start;
}
.actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 .message{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  z-index: 999;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>