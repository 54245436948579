<template>
    <div class="goods" style="margin:32px 20px;">
        <v-card  style="height:100%;" flat>
             <v-row style="margin-left:2.4%;padding-top:25px">
                <v-btn class="" color="primary" dark  style="margin-right:2.4%"
                @click="createVersion">
                    <v-icon
                    color="white"
                    >
                    mdi-view-grid-plus 
                    </v-icon>
                    <span>添加商品</span>
                </v-btn>
                <v-btn class="" color="success" dark  
                @click="copyVersion">
                    <v-icon
                    color="white"
                    >
                    mdi-content-copy  
                    </v-icon>
                    <span>复制商品</span>
                </v-btn>
            </v-row>
            <v-row style="margin-left:2.4%">
                <v-form
                ref="searchForm"
                v-model="valid"
                lazy-validation
                style="display:flex;margin:25px 0;"
                >
                <v-text-field 
                v-model="keyword" 
                style="width:265px;margin-right:40px"
                 @keyup.enter.prevent="search"
                require label="输入关键字">
                <v-icon
                slot="append"
                >
                mdi-magnify  
                </v-icon>
                </v-text-field>
                <v-select
                :items="parentName1"
                v-model="parenthashName1"
                style="width:200px;margin-right:40px"
                label="一级分类"
                no-data-text="暂无一级分类"
                @change="choseParent1(parenthashName1)"
                ></v-select>
                <v-select
                :items="sonName1"
                v-model="sonhashName1"
                style="width:200px"
                label="二级分类"
                no-data-text="暂无二级分类"
                ></v-select>
                <v-btn color="primary" @click="search" style="margin:10px 40px">查询</v-btn>
                <v-btn color="success" @click="resetForm" style="margin:10px 0">重置</v-btn>
                </v-form>
            </v-row>
            <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                <v-data-table
                fixed-header
                :headers="headers"
                :items="goodsData"
                 item-key="name"     
                :page.sync="page"
                @page-count="pageCount = $event"
                hide-default-footer
                no-data-text="暂无数据"
                style="width:95%; "
                :single-expand="true"
                >
                    <template v-slot:[`item.name`]="{ item }" >
                        <div v-if="searchPage" v-html=" keywords(item.name,keywordchange)"></div>
                        <div v-else>{{item.name}}</div>
                    </template>
                    <template v-slot:[`item.specs`]="{ item, index }">
                        <div v-if="item.hidden"  >
                            <div v-for="(item2,index) in item.specs" :key="index" class="sku">
                                <div style="display:flex;aligin-items:center">
                                    {{item2}}
                                </div>
                            </div>
                            <td v-if="item.specs.length>2" @click="hidden(item.hidden,index);" class="hidden">
                                隐藏 
                                <v-icon
                                color="primary"
                                >
                                 mdi-chevron-up
                                </v-icon> 
                            </td>
                        </div>
                        <div v-else>
                            <div v-for="(item2,index) in item.specs.slice(0,3)" :key="index" class="sku">
                                
                                <div style="display:flex;aligin-items:center" v-if="index<3">
                                    {{item2}}
                                </div>
                            </div>
                            <td v-if="item.specs.length>3"  @click="hidden(item.hidden,index);" class="hidden">
                                显示更多内容
                                <v-icon
                                color="primary"
                                >
                                 mdi-chevron-down
                                </v-icon>
                            </td>
                            
                        </div>
                    </template>
                    <template v-slot:[`item.sku`]="{ item }" >
                        <div v-if="item.hidden">
                            <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                <div style="min-width:100px"> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                            </div>
                        </div>
                        <div v-else >
                            <div v-for="(item2,index) in item.sku.slice(0,3)" :key="index" class="sku">
                                <div  v-if="index<3">
                                  <div style="min-width:100px"> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                </div>
                            </div>
                        </div>
                        <td v-if="item.sku.length>3" style="height: 55px">
                        </td>
                    </template>
                    <template v-slot:[`item.image`]="{ item }">
                        <div v-if="item.hidden">
                            <div v-for="(item2,index) in item.image" :key="index" class="sku">
                                <div style="height:100%;display:flex;align-items:center">
                                    <img :src="item2" alt="" height="60px">
                                </div>
                            </div>
                        </div>
                        <div v-else >
                             <div v-for="(item2,index) in item.image.slice(0,3)"  :key="index" class="sku">
                                <div style="height:100%;display:flex;align-items:center">
                                    <img :src="item2" alt="" height="60px">
                                </div>
                            </div>
                        </div>
                            <td v-if="item.image.length>3" style="height: 55px;">
                            </td>
                    </template>
                    <template v-slot:[`item.private`]="{ item }">
                        <v-switch
                            v-model="item.private"
                            @click="editPrivate(item.private,item.hash)"
                            inset
                        ></v-switch>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div style="width:90px">
                            <v-tooltip top>
                                <template v-slot:activator="{ on,attrs }">
                                    <v-icon
                                    color="primary"
                                    v-on="on"
                                    v-bind="attrs"
                                    style="margin-right:20px"
                                @click="goToVersionList(item.hash);updateDialog=true"
                                    >
                                    mdi-pencil-box-multiple-outline 
                                    </v-icon>
                                </template>
                                <span>编辑商品</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on,attrs }">
                                    <v-icon
                                    color="error"
                                    v-on="on"
                                    v-bind="attrs"
                                    style="margin-right:20px"
                                @click="deleteApplication(item.hash)"
                                    >
                                    mdi-delete
                                    </v-icon>
                                </template>
                                <span>删除</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-row>
            <v-pagination
                v-model="page"
                :length="pagetotal"
                :total-visible="7"
                style="margin-top:20px">
            </v-pagination>
            <div style="height:20px"></div>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                        {{message}}
                    </v-alert>
                    <v-card-title class="text-h8" style="display:flex;justify-content:center">你确定要删除该商品吗？</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">确定</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- 复制商品列表 -->
            <v-dialog
            v-model="copyDialog"
            max-width="800px"
            scrollable
            >
                <v-card >
                    <v-toolbar
                    dark
                    color="primary"
                    dense
                    >
                    <v-toolbar-title style="font-size:16px">复制商品</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="copyDialog = false;"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar>
                    <v-card-text style="max-height: 90%;">
                    <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden;">
                        <v-data-table
                        fixed-header
                        :headers="copyHeaders"
                        :items="copyGoodsData"
                        :page.sync="copyPage"
                        @page-count="copyPageCount = $event"
                        hide-default-footer
                         no-data-text="暂无数据"
                        style="width:95%;margin-top:45px;"
                        >
                            <!-- <template v-slot:[`item.sku`]="{ item }">
                                <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                    <div style="float:left">
                                        <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                        <div>价格: <span>{{(item2.price)/100}}.00</span></div>
                                        <div>单位: <span>{{item2.unit}}</span></div>
                                    </div>
                                    <img :src="item2.image" alt="" height="60px" style="float:right;">
                                </div>
                            </template> -->
                            <template v-slot:[`item.specs`]="{ item, index }">
                        <div v-if="item.hidden"  >
                            <div v-for="(item2,index) in item.specs" :key="index" class="sku">
                                <div style="display:flex;aligin-items:center">
                                    {{item2}}
                                </div>
                            </div>
                            <td v-if="item.specs.length>2" @click="copyHidden(item.hidden,index);" class="hidden">
                                隐藏 
                                <v-icon
                                color="primary"
                                >
                                 mdi-chevron-up
                                </v-icon> 
                            </td>
                        </div>
                        <div v-else>
                            <div v-for="(item2,index) in item.specs.slice(0,3)" :key="index" class="sku">
                                
                                <div style="display:flex;aligin-items:center" v-if="index<3">
                                    {{item2}}
                                </div>
                            </div>
                            <td v-if="item.specs.length>3"  @click="copyHidden(item.hidden,index);" class="hidden">
                                显示更多内容
                                <v-icon
                                color="primary"
                                >
                                 mdi-chevron-down
                                </v-icon>
                            </td>
                            
                        </div>
                    </template>
                    <template v-slot:[`item.sku`]="{ item }" >
                        <div v-if="item.hidden">
                            <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                 <div> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                            </div>
                        </div>
                        <div v-else >
                            <div v-for="(item2,index) in item.sku.slice(0,3)" :key="index" class="sku">
                                <div  v-if="index<3">
                                     <div > <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                </div>
                            </div>
                        </div>
                        <td v-if="item.sku.length>3" style="height: 55px">
                        </td>
                    </template>
                    <template v-slot:[`item.image`]="{ item }">
                        <div v-if="item.hidden">
                            <div v-for="(item2,index) in item.image" :key="index" class="sku">
                                <div style="height:100%;display:flex;align-items:center">
                                    <img :src="item2" alt="" height="60px">
                                </div>
                            </div>
                        </div>
                        <div v-else >
                             <div v-for="(item2,index) in item.image.slice(0,3)"  :key="index" class="sku">
                                <div style="height:100%;display:flex;align-items:center">
                                    <img :src="item2" alt="" height="60px">
                                </div>
                            </div>
                        </div>
                            <td v-if="item.image.length>3" style="height: 55px;">
                            </td>
                    </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- <v-btn class="ml-2" color="primary" dark @click="copyList(item.hash)">复制</v-btn> -->
                                <v-tooltip top>
                                    <template v-slot:activator="{ on,attrs }">
                                        <v-icon
                                        color="primary"
                                        v-on="on"
                                        v-bind="attrs"
                                        style="margin-right:20px"
                                        @click="copyList(item.hash)"
                                        >
                                        mdi-content-copy 
                                        </v-icon>
                                    </template>
                                    <span>复制商品</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-row>
                    <v-pagination
                    v-model="copyPage"
                    :length="copyPagetotal"
                    :total-visible="7"
                    style="margin:20px"></v-pagination>
                    <div style="height:20px"></div>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="orange darken-1"
                        text
                        @click="copyDialog = false"
                        >
                        关闭
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- 分类 -->
            <v-dialog v-model="categoryDialog" max-width="500px">
                <v-card >
                    <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                        {{message}}
                    </v-alert>
                    <v-card-title class="text-h8">设置该复制商品的商品分类</v-card-title>
                    <v-form
                        ref="form"
                        v-model="valid"
                        style="width:80%;margin:auto"
                        lazy-validation
                        >
                        <v-select
                        :items="parentName"
                        v-model="parenthashName"
                        :rules="[v => !!v || '一级分类不能为空']"
                        label="选择一级分类"
                        no-data-text="暂无一级分类"
                        @change="choseParent(parenthashName)"
                        ></v-select>
                        <v-select
                        :items="sonName"
                        v-model="sonhashName"
                        label="选择二级分类"
                        no-data-text="暂无二级分类"
                        ></v-select>
                    </v-form>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" text @click="categoryDialog=false; reset()">取消</v-btn>
                    <v-btn color="orange darken-1" text @click="copyGoodsBtn">确认复制</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>
import {goodsList,goodsList1,goodsList2,goodsList3,goodsItem,deleteGoods,setIsprivate,copyGoodsList,parentList,childList,copyGoods} from '@/api/goods.js'
export default {
    data(){
        return{
            valid:true,
            messageError:false,
            message:'',
            parentData1:[],
            parentName1:[],
            parenthashName1:'',
            sonhashName1:[],
            sonData1:'',
            sonName1:[],
            categoryhash1:'',
            goodsData:[],
            keyword:'',
            keywordchange:'',
            page:1,
            pageCount:10,
            pagetotal:1,
            copyGoodsData:[],
            copyPage:1,
            copyDialog:false,
            copyPageCount:10,
            copyPagetotal:1,
            parentData:[],
            parentName:[],
            hash:'',
            categoryhash:'',
            parenthashName:'',
            sonData:[],
            sonName:[],
            sonhashName:[],
            dialogDelete:false,
            deletHash:'',
            details:{},
            private:0,
            categoryDialog:false,
            searchPage:false,
            detailScrollHeight:251
        }
    },
    computed:{
        headers () {
            return [
                {
                    text: '商品名称',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text:'价格/单位',sortable: false,value:'sku'},
                {text:'规格',sortable: false,value:'specs'},
                {text:'图片',sortable: false,value:'image'},
                { text: '所属分类',sortable: false, value: 'category'},
                {text:'权限私有化',sortable: false,value:'private'},
                { text: '操作',sortable: false,value: 'actions' },
                // { text: '', value: 'data-table-expand' },
            ]
      },
      copyHeaders(){
          return [
                {
                    text: '商品名称',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text:'价格/单位',sortable: false,value:'sku'},
                {text:'规格',sortable: false,value:'specs'},
                {text:'图片',sortable: false,value:'image'},
                { text: '操作',sortable: false, value: 'actions' }
            ]
      },
       keywords(){
        return function(item,keyword) {
         let titleString = item;
            if (!titleString) {
                return '';
            }
            if (keyword && keyword.length > 0) {
                // 匹配关键字正则,g-全部，i-不区分大小写
                let replaceReg = new RegExp(keyword, 'gi');
                // 高亮替换v-html值
                // 开始替换
                titleString = titleString.replace(replaceReg, function(text){
                    return '<span class="search-text">' + text + '</span>'
                });
            }
            return titleString;
        }
       }
    },
     watch: {
        // 监视pagination属性的变化
        page: { 
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                if(this.searchPage){
                    this.changepage();
                }else{
                    this.getGoodsList()
                }
            // console.log(this.page);
            }
        },
         // 监视pagination属性的变化
        copyPage: { 
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                this.copyVersion()
            // console.log(this.page);
            }
        },
        sonhashName(val){
            // console.log(val);
            this.sonhashName = val;
            if(this.sonData){
                const sonhash = this.sonData.filter(item=>{
                    if(item.name === this.sonhashName){
                        return item
                    }
                }).map(item=>{
                    return item.hash
                })
                this.categoryhash = sonhash[0]
            }
            // console.log(sonhash[0]);
        },
        sonhashName1(val){
            // console.log(val);
            this.sonhashName1 = val;
            if(this.sonData1){
                const sonhash = this.sonData1.filter(item=>{
                    if(item.name === this.sonhashName1){
                        return item
                    }
                }).map(item=>{
                    return item.hash
                })
                this.categoryhash1 = sonhash[0]
            }
            
            // console.log(sonhash[0]);
        }
    },
    mounted(){
        this.getGoodsList();
        this.getParentsList()
        // this.getGoodsItem();
        
    },
    methods:{
        // slideOpen(event,){
        //    this.detailScrollHeight = event.path[3].scrollHeight;
        //    this.$nextTick(()=>{
        //     let detailScrollHeight = event.path[3].scrollHeight;
        //     event.path[3].style.transition="height 0.5s" ;
        //     event.path[3].style.height = detailScrollHeight + 'px';
        //     console.log(event.path[3].offsetHeight);
        //    })
        // },
        // slideHide(event){
        //     event.path[3].style.height =  this.detailScrollHeight + 'px';
        //     this.$nextTick(()=>{
        //         event.path[3].style.transition="height 0.5s" ;
        //         console.log(event.path[3].offsetHeight);
        //     })
        // },
        search(){
            if(this.keyword){
                this.searchPage = true
                this.keywordchange = this.keyword
                if(this.categoryhash1){
                    goodsList3(this.categoryhash1,this.keyword,1).then(res=>{
                        this.goodsData = res.data.data.list
                        const data = this.goodsData.map(item=>{
                            return item.sku
                        })
                        for(let i= 0; i<this.goodsData.length;i++){
                            const image = data[i].map(item=>{
                                return item.image
                            })
                            const specs = data[i].map(item=>{
                                return item.specs
                            })
                            this.goodsData[i]={
                                ... this.goodsData[i],
                                image:image,
                                specs:specs,
                                hidden:false
                            }
                        }
                        this.pagetotal = res.data.data.pagetotal
                        this.page = 1
                    })
                    return
                }
                goodsList1(this.keyword,1).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                    this.page = 1
                })
            }
            else if(this.categoryhash1){
                this.searchPage = true
                goodsList2(this.categoryhash1,1).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                    this.page = 1
                })
            }
            else{
                this.searchPage = false
                 goodsList(1).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                    this.page = 1
                 })
            }
        },
        changepage(){
            // console.log(this.page);
             if(this.keyword){
                 this.searchPage = true;
                if(this.categoryhash1){
                    goodsList3(this.categoryhash1,this.keyword,this.page).then(res=>{
                        this.goodsData = res.data.data.list
                        const data = this.goodsData.map(item=>{
                            return item.sku
                        })
                        for(let i= 0; i<this.goodsData.length;i++){
                            const image = data[i].map(item=>{
                                return item.image
                            })
                            const specs = data[i].map(item=>{
                                return item.specs
                            })
                            this.goodsData[i]={
                                ... this.goodsData[i],
                                image:image,
                                specs:specs,
                                hidden:false
                            }
                        }
                        this.pagetotal = res.data.data.pagetotal
                    })
                    return
                }
                goodsList1(this.keyword,this.page).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                })
            }
            else if(this.categoryhash1){
                this.searchPage = true;
                goodsList2(this.categoryhash1,this.page).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                })
            }
            else{
                this.searchPage =false;
                 goodsList(1).then(res=>{
                    this.goodsData = res.data.data.list
                    const data = this.goodsData.map(item=>{
                        return item.sku
                    })
                    for(let i= 0; i<this.goodsData.length;i++){
                        const image = data[i].map(item=>{
                            return item.image
                        })
                        const specs = data[i].map(item=>{
                            return item.specs
                        })
                        this.goodsData[i]={
                            ... this.goodsData[i],
                            image:image,
                            specs:specs,
                            hidden:false
                        }
                    }
                    this.pagetotal = res.data.data.pagetotal
                 })
            }
        },
         // 获取父类
        getParentsList(){
            parentList().then(res=>{
                this.parentData1 = res.data.data.list;
                // console.log(this.parentData1);
                this.parentData1.unshift({hash:'',name:'无'})
                this.parentName1 = this.parentData1.map(item=>{
                    return item.name
                })
                // this.parentName1.unshift('无')
                // console.log(this.parentName1);
            })
        },
        // 选择子类
         choseParent1(pHash){
            // console.log(pHash);
            this.parenthashName1 = pHash;
            if(this.parenthashName1==="无"){
                 this.sonData1 = [];
                 this.sonName1 = [];
                return false
            }
            const parenthash = this.parentData1.filter(item=>{
                if(item.name === this.parenthashName1){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            // console.log(parenthash);
            childList(parenthash[0]).then(res=>{
                this.sonData1 = res.data.data.list
                this.sonName1 = this.sonData1.map(item=>{
                    return item.name
                })
            })
            // console.log(this.sonData1);
        },
        getGoodsList(){
            goodsList(this.page).then(res=>{
                this.goodsData = res.data.data.list
                // console.log(this.goodsData);
                const data = this.goodsData.map(item=>{
                    return item.sku
                })
                for(let i= 0; i<this.goodsData.length;i++){
                    const image = data[i].map(item=>{
                        return item.image
                    })
                    const specs = data[i].map(item=>{
                        return item.specs
                    })
                    this.goodsData[i]={
                        ... this.goodsData[i],
                        image:image,
                        specs:specs,
                        hidden:false
                    }
                }
                // console.log(this.goodsData);
                this.pagetotal = res.data.data.pagetotal
                // console.log(this.goodsData);
            })
        },
        copyList(hash){
            this.categoryDialog = true;
            parentList().then(res=>{
                this.parentData = res.data.data.list
                this.parentName = this.parentData.map(item=>{
                    return item.name
                })
            })
            // console.log(hash);
            this.hash = hash
        },
        // copyFinish(){
        //     this.getGoodsList()
        // },
        choseParent(pHash){
            // console.log(pHash);
            this.parenthashName = pHash;
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            childList(parenthash[0]).then(res=>{
                this.sonData = res.data.data.list
                this.sonName = this.sonData.map(item=>{
                    return item.name
                })
            })

        },
        // 获取详情信息
        goToVersionList(hash){
             goodsItem(hash).then(res=>{
                this.details = res.data.data
                // console.log(this.details );
                this.$router.push({name:'editGoods', params:this.details});
            })
        },
        // 隐藏详细信息
        hidden(hidden,index){
            // console.log(hidden,index);
            this.goodsData[index].hidden = !hidden
            // console.log(this.goodsData[index].hidden);
        },
        copyHidden(hidden,index){
        // console.log(hidden,index);
        this.copyGoodsData[index].hidden = !hidden
        // console.log(this.goodsData[index].hidden);
        },
        // 编辑隐私权限
        editPrivate(pri,hash){
            
                if(pri){
                    let params = {
                        hash:hash,
                        isprivate:1
                    }
                    setIsprivate(params).then(res=>{
                        // console.log(res);
                    })
                }else{
                    let params = {
                        hash:hash,
                        isprivate:0
                    }
                    setIsprivate(params).then(res=>{
                        // console.log(res);
                    })
                }            
        },
        // 复制商品列表信息
        copyVersion(){
            this.copyDialog = true;
            copyGoodsList(this.copyPage).then(res=>{
                // console.log(res);
                this.copyGoodsData = res.data.data.list
                  const data = this.copyGoodsData.map(item=>{
                    return item.sku
                })
                for(let i= 0; i<this.copyGoodsData.length;i++){
                    const image = data[i].map(item=>{
                        return item.image
                    })
                    const specs = data[i].map(item=>{
                        return item.specs
                    })
                    this.copyGoodsData[i]={
                        ... this.copyGoodsData[i],
                        image:image,
                        specs:specs,
                        hidden:false
                    }
                }
                this.copyPagetotal = res.data.data.pagetotal
            })
        },
        // 确认复制商品
        copyGoodsBtn(){
            this.$refs.form.validate()
            var parmas = {
                hash:this.hash,
                categoryhash:this.categoryhash
            }
            copyGoods(parmas).then(res=>{
                if(res.data.code===200){
                //    console.log(res);
                    this.categoryDialog = false;
                    this.copyDialog = false;
                    this.getGoodsList();
                    this.reset();
                }else{
                    this.$nextTick(()=>{
                        // console.log(res);
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
        reset(){
            this.$nextTick(()=>{
                this.$refs.form.reset();
            })
            
        },
        resetForm(){
            this.$nextTick(()=>{
                // console.log(this.$refs.searchForm);
                this.sonData1=[];
                this.sonName1=[];
                this.$refs.searchForm.reset();
            })
        },
        createVersion(){
             this.$router.push({name:'addGoods'});
        },
        deleteApplication(hash){
            this.deletHash = hash
            this.dialogDelete = true ;
        },
        closeDelete(){
             this.dialogDelete = false;
        },
        deleteItemConfirm(){
            var params = {
                hash:this.deletHash
            }
            deleteGoods(params).then(res=>{
                // console.log(res);
                if(res.data.code===200){
                    this.dialogDelete = false;
                    this.getGoodsList();
                }else{
                    this.$nextTick(()=>{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
    }
}
</script>
<style >
.sku{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* border-bottom:1px dashed #ccc; */
    height: 65px;
    overflow: hidden;
}
.sku:last-child{
    border-bottom:none;
}
.toolbar-sty{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.toolbar-sty .v-toolbar__content{
    width: 100%;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
}
.hidden{
    height: 55px;
    color:#f57c00;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-text{
    color:red
}
 .message{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>