<template>
    <div class="quotation" style="margin:32px 20px;">
        <v-card  style="height:100%" flat>
             <v-row class="mb-6 pt-6 justify-start" style="margin-left:2.4%">
                <!-- 添加小店 -->
                <v-dialog
                v-model="addDialog"
                persistent
                max-width="80%"
                scrollable
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn class="" color="primary" dark  v-bind="attrs"
                    v-on="on" @click="createVersion">
                        <v-icon
                        color="white"
                        >
                        mdi-shape-square-plus  
                        </v-icon>
                        <span>添加报价小店</span>
                    </v-btn>
                    </template>
                    <v-card flat elevation="0">
                            <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                                {{message}}
                            </v-alert>
                            <v-stepper v-model="e1" style="z-index:5;width:100%" flat>
                               <v-app-bar style="font-size:16px;background-color:white;padding:0;" >
                                <v-stepper-header style="width:100%;box-shadow:none;">
                                <v-stepper-step
                                    :complete="e1 > 1"
                                    step="1"
                                >
                                    添加报价小店
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step
                                    :complete="e1 > 2"
                                    step="2"
                                >
                                为报价小店添加商品
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="3">
                                查看并修改商品价格
                                </v-stepper-step>
                                </v-stepper-header>
                               </v-app-bar>
                            </v-stepper>
                        <v-card-text :style="'max-height:'+screenHeight+'px;height:90%;border-top:1px solid #ccc'">
                            <v-stepper v-model="e1" flat>
                                 <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div style="font-size:20px;font-weight:600">报价小店基本信息</div>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                            <v-form
                                                            ref="form"
                                                            v-model="valid"
                                                            lazy-validation
                                                            >
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-text-field v-model="name" class="mx-auto col-11 account-input" :rules="[v => !!v || '名称不能为空']" label="报价小店名称" require />
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-text-field v-model="contacts" class="mx-auto col-11 account-input" :rules="[v => !!v || '联系人姓名不能为空']" label="联系人姓名" require />
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-file-input
                                                                    v-model="avatarInfo"
                                                                    required
                                                                    show-size accept="image/png, image/jpeg, image/bmp"
                                                                    class="mx-auto col-11 account-input"
                                                                    @change="uploadAvatar"
                                                                     :rules="[v => !!v || '头像不能为空']"
                                                                    label="点击上传头像">
                                                                    </v-file-input>
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <div  class="content-head">
                                                                        <img v-if="avatarImage" :src="avatarImage"  height="100px"> 
                                                                    </div>
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                style="display:flex"
                                                                >
                                                                    <v-text-field v-model="mobilenumber" class="mx-auto col-5 account-input" label="联系人手机号码" :rules="[mobileRule]" require />
                                                                    <v-text-field v-model="wechatnumber" class="mx-auto col-5 account-input" label="联系人微信号"  :rules="[v => !!v || '微信号不能为空']" require />
                                                                </v-col>
                                                            </v-form>
                                                        </v-col>
                                                        
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="5">
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div ><span style="font-size:20px;font-weight:600;">填写公告</span> 
                                                                <div
                                                                
                                                                width="80%"
                                                                style="display:inline-block;margin-left:20px;font-size:14px;color:#f57c00;padding:2px"
                                                                >
                                                            ( 限制3条 )
                                                            </div>
                                                            </div> 
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div style="display:flex;justify-content:center;align-items:center;border:1px solid #999">
                                                                <v-col
                                                                    cols="12"
                                                                    md="10"
                                                                >
                                                                    <v-text-field
                                                                        v-model="value" 
                                                                        label="填写公告"
                                                                        class="mx-auto col-12" 
                                                                        require
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                sm="2"
                                                                style="padding-left:0px"
                                                                >
                                                                    <v-btn
                                                                    class="mx-2"
                                                                    color="primary"
                                                                   
                                                                    @click="addNoticeData()"
                                                                    >
                                                                    添加
                                                                    </v-btn>
                                                                </v-col>
                                                            </div>
                                                            <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="12">
                                                                <v-alert
                                                                v-for="(item,index)  in notice" :key="index"
                                                                class="ma-2 col-12"
                                                                color="warning"
                                                                style="position: relative;"
                                                                outlined
                                                                >
                                                                {{item}}
                                                                    <v-icon
                                                                    small
                                                                    @click="deleteValue(index)"
                                                                    style="position: absolute;right:5px;top:50%;transform: translateY(-50%);"
                                                                    color="warning"
                                                                    >
                                                                    mdi-close
                                                                    </v-icon>
                                                                </v-alert>
                                                            </v-col>
                                                        </v-col>
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="5">
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div ><span style="font-size:20px;font-weight:600;">增加广告</span> 
                                                                <div
                                                                width="80%"
                                                                style="display:inline-block;margin-left:20px;font-size:14px;color:#f57c00;padding:2px"
                                                                >
                                                                ( 限制3条 )
                                                                </div>
                                                            </div> 
                                                            
                                                        </v-col>
                                                         <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                            <v-file-input
                                                            v-model="fileInfo"
                                                            required
                                                            show-size accept="image/png, image/jpeg, image/bmp"
                                                            @change="uploadImage"
                                                            label="点击上传广告栏图片">
                                                            </v-file-input>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                            <div  v-for="(item,index) in images" :key="index" style="position: relative;display:flex;border:1px solid #999;margin-bottom:15px">
                                                                <!-- <img :src="item" class="image" width="100px"> -->
                                                                    <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="4"
                                                                    style="display:flex;justify-content:center;align-items:center"
                                                                    >
                                                                        <img :src="item" alt="" width="100%"> 
                                                                        <v-icon
                                                                        color="primary"
                                                                        small
                                                                        @click="deleteKey(item,index)"
                                                                        style="position: absolute;top:2px;right:2px"
                                                                        >
                                                                            mdi-close
                                                                        </v-icon> 
                                                                    </v-col>
                                                                    <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    >
                                                                        <v-select
                                                                        :items="parentName"
                                                                        v-model="parenthashName[index]"
                                                                        class="mx-auto col-12"
                                                                        label="一级分类"
                                                                        no-data-text="暂无一级分类"
                                                                        outlined
                                                                        @change="choseParent(parenthashName[index],index)"
                                                                        ></v-select>
                                                                        <v-select
                                                                        :items="sonNameList[index]"
                                                                        v-model="sonhashName[index]"
                                                                        label="二级分类"
                                                                        no-data-text="暂无二级分类"
                                                                        @change="sonhashNameList(sonhashName[index],index)"
                                                                        class="mx-auto col-12"
                                                                        outlined
                                                                        ></v-select>
                                                                    </v-col>
                                                            </div>
                                                        </v-col>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-card flat>
                                             <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                                                {{message}}
                                            </v-alert>
                                            <v-alert v-show="messageSuccess" type="success" :class="messageSuccess?'message':''" dense>
                                                {{message}}
                                            </v-alert>
                                            <!-- <v-card-title>
                                            <span class="text-h5">添加商品</span>
                                            </v-card-title> -->
                                            <v-card-text>
                                                <v-container>
                                                    <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                                                        <v-data-table
                                                        fixed-header
                                                        :headers="addHeaders"
                                                        :items="addShopsData"
                                                        :page.sync="addPage"
                                                        @page-count="addPageCount = $event"
                                                        hide-default-footer
                                                         no-data-text="暂无数据"
                                                        style="width:95%;"
                                                        >   
                                                            <!-- <template v-slot:[`item.sku`]="{ item }">
                                                                <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                                                    <div style="float:left">
                                                                        <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                                                        <div>价格: <span>{{(item2.price)/100}}.00</span></div>
                                                                        <div>单位: <span>{{item2.unit}}</span></div>
                                                                    </div>
                                                                    <img :src="item2.image" alt="" height="60px" style="float:right;">
                                                                </div>
                                                            </template> -->
                                                            <template v-slot:[`item.specs`]="{ item, index }">
                                                                <div v-if="item.hidden"  >
                                                                    <div v-for="(item2,index) in item.specs" :key="index" class="sku">
                                                                        <div style="display:flex;aligin-items:center">
                                                                            {{item2}}
                                                                        </div>
                                                                    </div>
                                                                    <td v-if="item.specs.length>2" @click="hidden(item.hidden,index);" class="hidden">
                                                                        隐藏 
                                                                        <v-icon
                                                                        color="primary"
                                                                        >
                                                                        mdi-chevron-up
                                                                        </v-icon> 
                                                                    </td>
                                                                </div>
                                                                <div v-else>
                                                                    <div v-for="(item2,index) in item.specs.slice(0,3)" :key="index" class="sku">
                                                                        
                                                                        <div style="display:flex;aligin-items:center" v-if="index<3">
                                                                            {{item2}}
                                                                        </div>
                                                                    </div>
                                                                    <td v-if="item.specs.length>3"  @click="hidden(item.hidden,index);" class="hidden">
                                                                        显示更多内容
                                                                        <v-icon
                                                                        color="primary"
                                                                        >
                                                                        mdi-chevron-down
                                                                        </v-icon>
                                                                    </td>
                                                                    
                                                                </div>
                                                            </template>
                                                            <template v-slot:[`item.sku`]="{ item }" >
                                                                <div v-if="item.hidden">
                                                                    <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                                                        <div> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div v-else >
                                                                    <div v-for="(item2,index) in item.sku.slice(0,3)" :key="index" class="sku">
                                                                        <div  v-if="index<3">
                                                                           <div> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <td v-if="item.sku.length>3" style="height: 55px">
                                                                </td>
                                                            </template>
                                                            <template v-slot:[`item.image`]="{ item }">
                                                                <div v-if="item.hidden">
                                                                    <div v-for="(item2,index) in item.image" :key="index" class="sku">
                                                                        <div style="height:100%;display:flex;align-items:center">
                                                                            <img :src="item2" alt="" height="60px">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-else >
                                                                    <div v-for="(item2,index) in item.image.slice(0,3)"  :key="index" class="sku">
                                                                        <div style="height:100%;display:flex;align-items:center">
                                                                            <img :src="item2" alt="" height="60px">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    <td v-if="item.image.length>3" style="height: 55px;">
                                                                    </td>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <!-- {{item}} -->
                                                                <div v-if="item.added" style="min-width:64px">已添加</div>
                                                                <v-btn v-else color="primary" dark @click="addGood(item.hash);item.added=1">添加</v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-row>
                                                    <v-pagination
                                                    v-model="addPage"
                                                    :length="addPagetotal"
                                                    :total-visible="7"
                                                    style="margin:20px"></v-pagination>
                                                    <div style="height:20px"></div>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="3">
                                        <v-card flat>
                                            <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                                                {{message}}
                                            </v-alert>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                                                        <v-data-table
                                                        fixed-header
                                                        :headers="addEditHeaders"
                                                        :items="addEditGoodsData"
                                                        :page.sync="addEditPage"
                                                        @page-count="addEditPageCount = $event"
                                                        hide-default-footer
                                                         no-data-text="暂无数据"
                                                        style="width:95%;"
                                                        >   
                                                            <template v-slot:[`item.sku`]="{ item }">
                                                                <div v-for="(item2,index) in item.sku" :key="index" class="sku-add-goods">
                                                                    <div style="float:left">
                                                                        <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                                                        <div>价格:
                                                                            <input v-model="item2.price" type="text" ref="priceRef" class="price" @blur="changePrice(item2.spechash,item2.price)"></div>
                                                                        <div>单位: <span>{{item2.unit}}</span></div>
                                                                    </div>
                                                                    <img :src="item2.image" alt="" height="60px" style="float:right;">
                                                                    <!-- <img v-if="item2.image" src="@/assets/1.jpg" alt="" height="60px" style="float:right;"> -->
                                                                </div>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <!-- {{item}} -->
                                                                <!-- <v-btn color="error" dark @click="deleteGood(item.hash);">删除</v-btn> -->
                                                                 <v-tooltip top>
                                                                    <template v-slot:activator="{ on,attrs }">
                                                                        <v-icon
                                                                        color="error"
                                                                        v-on="on"
                                                                        v-bind="attrs"
                                                                        style="margin-right:20px"
                                                                        @click="deleteGood(item.hash);"
                                                                        >
                                                                        mdi-delete
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>删除</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-data-table>
                                                    </v-row>
                                                    <v-pagination
                                                    v-model="addEditPage"
                                                    :length="addEditPagetotal"
                                                    :total-visible="7"
                                                    style="margin:20px"></v-pagination>
                                                    <div style="height:20px"></div>
                                                    <!-- 删除商品 -->
                                                    <v-dialog v-model="delGoodsDialog" max-width="500px">
                                                        <v-card>
                                                            <v-card-title class="text-h8" style="display:flex;justify-content:center">你确定要删除该报价商品吗？</v-card-title>
                                                            <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="orange darken-1" text @click="delGoodsDialog = false">取消</v-btn>
                                                            <v-btn color="orange darken-1" text @click="deleteGoodsItem">确定</v-btn>
                                                            <v-spacer></v-spacer>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog> 
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-card-text>
                        <v-card-actions class="card-actions" v-if="e1 === 1">
                            <v-spacer></v-spacer>
                            <v-btn
                            color="orange darken-1"
                            text
                            @click="addDialog = false;reset();"
                            >
                            取消
                            </v-btn>
                            <v-btn
                            color="orange darken-1"
                            text
                            @click="validateShops()"
                            >
                            下一步
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions  class="card-actions" v-else-if="e1 === 2">
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            outlined
                            @click="addDialog = false;reset();"
                            >
                            关闭
                            </v-btn>
                            <v-btn
                            color="primary"
                            outlined
                            @click="addGoodsConfirm()"
                            >
                            查看并修改商品价格
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions  class="card-actions" v-else>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="primary"
                            outlined
                            @click="addDialog = false;reset();"
                            >
                            关闭
                            </v-btn>
                            <v-btn
                            color="primary"
                            outlined
                            @click="addGoods();e1 =2;"
                            >
                            返回商品列表
                            </v-btn>
                            <v-btn
                            color="primary"
                            outlined
                            @click="getQrcode()"
                            >
                            打开二维码
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn class="" color="success" dark style="margin-left:2.4%" 
                @click="copyVersion">
                    <v-icon
                    color="white"
                    >
                    mdi-content-copy  
                    </v-icon>
                    <span> 复制报价小店</span>
                </v-btn>
            </v-row>
            <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                <v-data-table
                fixed-header
                :headers="headers"
                :items="shopsData"
                :page.sync="page"
                @page-count="pageCount = $event"
                hide-default-footer
                no-data-text="暂无数据"
                style="width:95%;"
                >   
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                 @click="goToVersionList(item.hash);updateDialog=true;"
                                >
                               mdi-pencil-box-multiple-outline 
                                </v-icon>
                            </template>
                            <span>编辑信息</span>
                        </v-tooltip>
                        <!-- <v-btn class="ml-2" color="primary" dark @click="addGoods(item.hash);updateDialog=true">添加商品</v-btn> -->
                         <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                @click="addGoods(item.hash);updateDialog=true"
                                >
                                 mdi-basket-plus-outline 
                                </v-icon>
                            </template>
                            <span>为小店添加商品</span>
                        </v-tooltip>
                        <!-- <v-btn class="ml-2" color="primary" dark @click="editGoods(item.hash)">编辑商品</v-btn> -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="blue"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                 @click="editGoods(item.hash)"
                                >
                                 mdi-square-edit-outline 
                                </v-icon>
                            </template>
                            <span>修改小店商品价格</span>
                        </v-tooltip>
                        <!-- <v-btn class="ml-2" color="primary" dark @click="getQrcode(item.hash);qrcodeDialog = true">二维码</v-btn> -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="purple"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                @click="getQrcode(item.hash);qrcodeDialog = true"
                                >
                                 mdi-qrcode 
                                </v-icon>
                            </template>
                            <span>二维码</span>
                        </v-tooltip>
                        <!-- <v-btn class="ml-2" color="error" dark @click="deleteApplication(item.hash)">删除</v-btn> -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="error"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                 @click="deleteApplication(item.hash)"
                                >
                                 mdi-delete
                                </v-icon>
                            </template>
                            <span>删除</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-row>
            <v-pagination
            v-model="page"
            :length="pagetotal"
            :total-visible="7"
            style="margin:20px"></v-pagination>
            <div style="height:20px"></div>
            <!-- 修改小店 -->
            <v-dialog
            v-model="updateDialog"
            persistent
            width="80%"
            scrollable
            >
                <v-card flat>
                    <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                        {{message}}
                    </v-alert>
                    <v-stepper v-model="e2" style="z-index:5;width:100%" flat>
                    <v-app-bar style="font-size:16px;background-color:white" >
                        <v-stepper-header style="width:100%; border:none;box-shadow:none" >
                        <v-stepper-step
                            :complete="e2 > 1"
                            step="1"
                        >
                            编辑报价小店
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="e2 > 2"
                            step="2"
                        >
                        为报价小店添加商品
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">
                        查看并修改商品价格
                        </v-stepper-step>
                        </v-stepper-header>
                        </v-app-bar>
                    </v-stepper>
                    <v-card-text :style="'max-height:'+screenHeight+'px;height:90%;border-top:1px solid #ccc'">
                        <v-stepper v-model="e2" flat>
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div style="font-size:20px;font-weight:600">报价小店基本信息</div>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                            <v-form
                                                            ref="form"
                                                            v-model="valid"
                                                            lazy-validation
                                                            >
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-text-field v-model="name" class="mx-auto col-11 account-input" :rules="[v => !!v || '名称不能为空']" label="报价小店名称" require />
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-text-field v-model="contacts" class="mx-auto col-11 account-input"  :rules="[v => !!v || '联系人姓名不能为空']" label="联系人姓名" require />
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <v-file-input
                                                                    v-model="avatarInfo"
                                                                    required
                                                                    show-size accept="image/png, image/jpeg, image/bmp"
                                                                    class="mx-auto col-11 account-input"
                                                                    @change="uploadAvatar"
                                                                    label="点击上传头像">
                                                                    </v-file-input>
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                >
                                                                    <div  class="content-head">
                                                                        <img v-if="avatarImage" :src="avatarImage"  height="100px"> 
                                                                    </div>
                                                                </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="12"
                                                                style="display:flex"
                                                                >
                                                                    <v-text-field v-model="mobilenumber" class="mx-auto col-5 account-input" label="联系人手机号码" :rules="[mobileRule]" require />
                                                                    <v-text-field v-model="wechatnumber" class="mx-auto col-5 account-input" :rules="[v => !!v || '微信号不能为空']" label="联系人微信号" require />
                                                                </v-col>
                                                            </v-form>
                                                        </v-col>
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="5">
                                                    </v-col>
                                                    
                                                       <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                         <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div ><span style="font-size:20px;font-weight:600;">填写公告</span> 
                                                                <div
                                                                width="80%"
                                                                style="display:inline-block;margin-left:20px;font-size:14px;color:#f57c00;padding:2px"
                                                                >
                                                            ( 限制3条 )
                                                            </div>
                                                            </div> 
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                        <div style="display:flex;align-items:center;border:1px solid #999">
                                                            <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="10"
                                                            >
                                                                <v-text-field
                                                                    v-model="value" 
                                                                    label="填写公告"
                                                                    class="mx-auto col-12" 
                                                                    require
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="2"
                                                            >
                                                                <v-btn
                                                                class="mx-2"
                                                                color="primary"
                                                                @click="addNoticeData()"
                                                                >
                                                                添加
                                                                </v-btn>
                                                            </v-col>
                                                        </div>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                                <v-alert
                                                                v-for="(item,index)  in notice" :key="index"
                                                                class="ma-2 col-12"
                                                                color="warning"
                                                                style="position: relative;"
                                                                outlined
                                                                >
                                                                {{item}}
                                                                    <v-icon
                                                                    small
                                                                    @click="deleteValue(index)"
                                                                    style="position: absolute;right:5px;top:50%;transform: translateY(-50%);"
                                                                    color="warning"
                                                                    >
                                                                    mdi-close
                                                                    </v-icon>
                                                                </v-alert>
                                                        </v-col>
                                                        </v-col>
                                                    </v-col>
                                                     <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12">
                                                            <div ><span style="font-size:20px;font-weight:600;">增加广告</span> 
                                                                <div
                                                                width="80%"
                                                                style="display:inline-block;margin-left:20px;font-size:14px;color:#f57c00;padding:2px"
                                                                >
                                                            ( 限制3条 )
                                                            </div>
                                                            </div> 
                                                            
                                                        </v-col>
                                                         <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                                <v-file-input
                                                                v-model="fileInfo"
                                                                required
                                                                show-size accept="image/png, image/jpeg, image/bmp"
                                                                @change="uploadImage"
                                                                label="点击上传广告栏图片">
                                                                </v-file-input>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="12"
                                                        >
                                                            <div v-for="(item,index) in images" :key="index" style="position: relative;display:flex;border:1px solid #999;margin-bottom:15px">
                                                                <!-- <img :src="item" class="image" width="100px"> -->
                                                                    <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="4"
                                                                    style="display:flex;justify-content:center;align-items:center"
                                                                    >
                                                                        <img :src="item" alt="" width="100%">                                                                
                                                                        <v-icon
                                                                        color="primary"
                                                                        small
                                                                        @click="deleteKey(item,index)"
                                                                        style="position: absolute;top:2px;right:2px"
                                                                        >
                                                                            mdi-close
                                                                        </v-icon> 
                                                                    </v-col>
                                                                <v-col
                                                                cols="12"
                                                                sm="6"
                                                                md="6"
                                                                >
                                                                <v-select
                                                                :items="parentName"
                                                                v-model="parenthashName[index]"
                                                                class="mx-auto col-12"
                                                                label="一级分类"
                                                                no-data-text="暂无一级分类"
                                                                outlined
                                                                @change="choseParent(parenthashName[index],index)"
                                                                ></v-select>
                                                                <v-select
                                                                :items="sonNameList[index]"
                                                                v-model="sonhashName[index]"
                                                                label="二级分类"
                                                                no-data-text="暂无二级分类"
                                                                 @change="sonhashNameList(sonhashName[index],index)"
                                                                class="mx-auto col-12"
                                                                outlined
                                                                ></v-select>
                                                            </v-col>
                                                            </div>
                                                        </v-col>
                                                     </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <!-- <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            color="orange darken-1"
                                            text
                                            @click="addDialog = false;reset();"
                                            >
                                            取消
                                            </v-btn>
                                            <v-btn
                                            color="orange darken-1"
                                            text
                                            @click="validateShops()"
                                            >
                                            下一步
                                            </v-btn>
                                        </v-card-actions> -->
                                    </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-card style="height:100%" flat>
                                        <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                                            {{message}}
                                        </v-alert>
                                        <v-alert v-show="messageSuccess" type="success" :class="messageSuccess?'message':''" dense>
                                            {{message}}
                                        </v-alert>
                                        <v-card-text >
                                            <v-container>
                                                <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                                                    <v-data-table
                                                    fixed-header
                                                    :headers="addHeaders"
                                                    :items="addShopsData"
                                                    :page.sync="addPage"
                                                    @page-count="addPageCount = $event"
                                                    hide-default-footer
                                                    no-data-text="暂无数据"
                                                    style="width:95%;"
                                                    >   
                                                        <!-- <template v-slot:[`item.sku`]="{ item }">
                                                            <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                                                <div style="float:left">
                                                                    <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                                                    <div>价格: <span>{{(item2.price)/100}}.00</span></div>
                                                                    <div>单位: <span>{{item2.unit}}</span></div>
                                                                </div>
                                                                <img :src="item2.image" alt="" height="60px" style="float:right;">
                                                            </div>
                                                        </template> -->
                                                         <template v-slot:[`item.specs`]="{ item, index }">
                                                            <div v-if="item.hidden"  >
                                                                <div v-for="(item2,index) in item.specs" :key="index" class="sku">
                                                                    <div style="display:flex;aligin-items:center">
                                                                        {{item2}}
                                                                    </div>
                                                                </div>
                                                                <td v-if="item.specs.length>2" @click="hidden(item.hidden,index);" class="hidden">
                                                                    隐藏 
                                                                    <v-icon
                                                                    color="primary"
                                                                    >
                                                                    mdi-chevron-up
                                                                    </v-icon> 
                                                                </td>
                                                            </div>
                                                            <div v-else>
                                                                <div v-for="(item2,index) in item.specs.slice(0,3)" :key="index" class="sku">
                                                                    
                                                                    <div style="display:flex;aligin-items:center" v-if="index<3">
                                                                        {{item2}}
                                                                    </div>
                                                                </div>
                                                                <td v-if="item.specs.length>3"  @click="hidden(item.hidden,index);" class="hidden">
                                                                    显示更多内容
                                                                    <v-icon
                                                                    color="primary"
                                                                    >
                                                                    mdi-chevron-down
                                                                    </v-icon>
                                                                </td>
                                                                
                                                            </div>
                                                        </template>
                                                        <template v-slot:[`item.sku`]="{ item }" >
                                                            <div v-if="item.hidden">
                                                                <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                                                   <div> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                                                </div>
                                                            </div>
                                                            <div v-else >
                                                                <div v-for="(item2,index) in item.sku.slice(0,3)" :key="index" class="sku">
                                                                    <div  v-if="index<3">
                                                                       <div> <span>￥{{item2.price/100}}/{{item2.unit}}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <td v-if="item.sku.length>3" style="height: 55px">
                                                            </td>
                                                        </template>
                                                        <template v-slot:[`item.image`]="{ item }">
                                                            <div v-if="item.hidden">
                                                                <div v-for="(item2,index) in item.image" :key="index" class="sku">
                                                                    <div style="height:100%;display:flex;align-items:center">
                                                                        <img :src="item2" alt="" height="60px">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else >
                                                                <div v-for="(item2,index) in item.image.slice(0,3)"  :key="index" class="sku">
                                                                    <div style="height:100%;display:flex;align-items:center">
                                                                        <img :src="item2" alt="" height="60px">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                <td v-if="item.image.length>3" style="height: 55px;">
                                                                </td>
                                                        </template>
                                                        <template v-slot:[`item.actions`]="{ item }">
                                                            <!-- {{item}} -->
                                                            <div v-if="item.added" style="min-width:64px">已添加</div>
                                                            <v-btn v-else color="primary" dark @click="addGood(item.hash);item.added=1">添加</v-btn>
                                                        </template>
                                                    </v-data-table>
                                                </v-row>
                                                <v-pagination
                                                v-model="addPage"
                                                :length="addPagetotal"
                                                :total-visible="7"
                                                style="margin:20px"></v-pagination>
                                                <div style="height:20px"></div>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <v-card style="height:100%" flat>
                                        <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                                            {{message}}
                                        </v-alert>
                                        <v-card-text>
                                            <v-container>
                                                <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                                                    <v-data-table
                                                    fixed-header
                                                    :headers="editHeaders"
                                                    :items="editGoodsData"
                                                    :page.sync="editPage"
                                                    @page-count="editPageCount = $event"
                                                    hide-default-footer
                                                    no-data-text="暂无数据"
                                                    style="width:95%;"
                                                    >   
                                                        <template v-slot:[`item.sku`]="{ item }">
                                                            <div v-for="(item2,index) in item.sku" :key="index" class="sku-add-goods">
                                                                <div style="float:left">
                                                                    <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                                                    <div>价格:
                                                                        <input v-model="item2.price" type="text" ref="priceRef" class="price" @blur="changePrice(item2.spechash,item2.price)"></div>
                                                                    <div>单位: <span>{{item2.unit}}</span></div>
                                                                </div>
                                                                <img :src="item2.image" alt="" height="60px" style="float:right;">
                                                                <!-- <img v-if="item2.image" src="@/assets/1.jpg" alt="" height="60px" style="float:right;"> -->
                                                            </div>
                                                        </template>
                                                        <template v-slot:[`item.actions`]="{ item }">
                                                            <!-- {{item}} -->
                                                            <!-- <v-btn color="error" dark @click="deleteGood(item.hash);">删除</v-btn> -->
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on,attrs }">
                                                                    <v-icon
                                                                    color="error"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    style="margin-right:20px"
                                                                    @click="deleteGood(item.hash);"
                                                                    >
                                                                    mdi-delete
                                                                    </v-icon>
                                                                </template>
                                                                <span>删除</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-data-table>
                                                </v-row>
                                                <v-pagination
                                                v-model="editPage"
                                                :length="editPagetotal"
                                                :total-visible="7"
                                                style="margin:20px"></v-pagination>
                                                <div style="height:20px"></div>
                                                <!-- 删除商品 -->
                                                <v-dialog v-model="delGoodsDialog" max-width="500px">
                                                    <v-card>
                                                        <v-card-title class="text-h8" style="display:flex;justify-content:center">你确定要删除该报价商品吗？</v-card-title>
                                                        <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="orange darken-1" text @click="delGoodsDialog = false">取消</v-btn>
                                                        <v-btn color="orange darken-1" text @click="deleteGoodsItem">确定</v-btn>
                                                        <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog> 
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                    <v-card-actions class="card-actions" v-if="e2===1">
                        <v-spacer></v-spacer>
                        <v-btn
                        color="orange darken-1"
                        text
                        @click="updateDialog = false;reset();"
                        >
                        关闭
                        </v-btn>
                        <v-btn
                        color="orange darken-1"
                        text
                        @click="editInformation()"
                        >
                        确认修改
                        </v-btn>
                        <v-btn
                        color="orange darken-1"
                        text
                        @click="commitEditShops()"
                        >
                        为小店添加商品
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions class="card-actions"  v-else-if="e2===2">
                        <v-spacer></v-spacer>
                        <v-btn
                        color="primary"
                        outlined
                            @click="updateDialog = false;reset();"
                        >
                        关闭
                        </v-btn>
                        <v-btn
                        color="primary"
                        outlined
                        @click="editGoods()"
                        >
                        查看并修改商品价格
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions class="card-actions" v-else>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="primary"
                        outlined
                        @click="updateDialog = false;reset();"
                        >
                            关闭
                        </v-btn>
                        <v-btn
                        color="primary"
                        outlined
                        @click="addGoods();e2 =2;"
                        >
                            返回商品列表
                        </v-btn>
                        <v-btn
                        color="primary"
                        outlined
                        @click="getQrcode()"
                        >
                            打开二维码
                        </v-btn>
                    </v-card-actions>
                    </v-card>
            </v-dialog>
           
            <!-- 删除小店 -->
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h12" style="display:flex;justify-content:center">删除报价小店后不会增加报价小店可创建数</v-card-title>
                    <div style="text-align:center;font-size:20px">是否确定删除？</div> 
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" text @click="closeDelete">取消</v-btn>
                    <v-btn color="error" text @click="deleteItemConfirm">确定</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog> 
            <!-- 复制小店列表 -->
            <v-row justify="center">
                <v-dialog
                v-model="copyDialog"
                persistent
                max-width="800px"
                scrollable
                >
                    <v-card>
                        
                        <v-toolbar
                        dark
                        color="primary"
                        dense
                        >
                        <v-toolbar-title style="font-size:16px">复制报价小店</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="copyDialog = false;copyDialogClose()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text style="max-height:90%">
                        <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden;">
                            <v-data-table
                            fixed-header
                            :headers="copyHeaders"
                            :items="copyShopsData"
                            :page.sync="copyPage"
                            @page-count="copyPageCount = $event"
                            hide-default-footer
                             no-data-text="暂无数据"
                            style="width:95%;margin-top:45px;"
                            >
                                <template v-slot:[`item.sku`]="{ item }">
                                    <div v-for="(item2,index) in item.sku" :key="index" class="sku">
                                    <div v-if="item2.specs">规格: <span>{{item2.specs}}</span></div>
                                    <div>价格: <span>{{item2.price}}</span></div>
                                    <div>单位: <span>{{item2.unit}}</span></div>
                                    </div>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <!-- <v-btn class="ml-2" color="primary" dark @click="copyList(item.hash)">复制</v-btn> -->
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on,attrs }">
                                                <v-icon
                                                color="primary"
                                                v-on="on"
                                                v-bind="attrs"
                                                style="margin-right:20px"
                                                @click="copyList(item.hash)"
                                                >
                                               mdi-content-copy 
                                                </v-icon>
                                            </template>
                                            <span>复制报价小店</span>
                                        </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-row>
                        <v-pagination
                        v-model="copyPage"
                        :length="copyPagetotal"
                        :total-visible="7"
                        style="margin:20px"></v-pagination>
                        <div style="height:20px"></div>
                        </v-card-text>
                         <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                            color="orange darken-1"
                            text
                            @click="copyDialog = false"
                            >
                            关闭
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- 分类 -->
                <v-dialog v-model="copyShopsDialog" max-width="500px">
                    <v-card >
                        <v-card-title class="text-h8" style="display:flex;justify-content:center">你确定复制该报价小店吗？</v-card-title>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange darken-1" text @click="copyShopsDialog=false">取消</v-btn>
                        <v-btn color="orange darken-1" text @click="copyGoodsBtn">确认复制</v-btn>
                        <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- 获取二维码 -->
             <v-dialog
                v-model="qrcodeDialog"
                persistent
                max-width="80%"
                scrollable
                >
                    <v-card>
                    <v-card-title>
                    <span class="text-h5">二维码</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                    <v-row class="qrcode">
                         <!-- <img :src="qrcodeh5url" alt="">
                         <img :src="qrcodebdminiappdouyin" alt="">
                         <img :src="qrcodebdminiapphuoshan" alt="">
                         <img :src="qrcodebdminiapppipixia" alt="">
                         <img :src="qrcodebdminiapptoutiao" alt=""> -->
                         
                         <div>
                            <img  :src="qrcodeh5url" alt="" width="200px">
                            <div>H5</div>
                            <v-btn class="" color="primary" @click="downsDebounce(qrcodeh5url,'tpshop-h5')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                         <div>
                            <img :src="qrcodebdminiappdouyin" alt="" width="200px">
                            <div>抖音</div>
                            <v-btn class="" color="primary" @click="downsDebounce(qrcodebdminiappdouyin,'tpshop-douyin')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                         <div>
                            <img :src="qrcodebdminiapphuoshan" alt="" width="200px">
                            <div>火山</div>
                            <v-btn class="" color="primary" @click="downsDebounce(qrcodebdminiapphuoshan,'tpshop-huoshan')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                         <div>
                            <img :src="qrcodebdminiapppipixia" alt="" width="200px">
                            <div>皮皮虾</div>
                            <v-btn class="" color="primary"  @click="downsDebounce(qrcodebdminiapppipixia,'tpshop-pipixia')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                         <div>
                            <img :src="qrcodebdminiapptoutiao" alt="" width="200px">
                            <div>头条</div>
                            <v-btn class="" color="primary" @click="downsDebounce(qrcodebdminiapptoutiao,'tpshop-toutiao')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                         <div>
                            <img  :src="qrcodewxminiapp" alt="" width="200px">
                            <div>微信</div>
                            <v-btn class="" color="primary" @click="downsDebounce(qrcodewxminiapp,'tpshop-wechat')" outlined >
                                <v-icon
                                color="primary"
                                >
                                mdi-download-outline  
                                </v-icon>
                                <span>下载</span>
                            </v-btn>
                         </div>
                    </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="orange darken-1"
                    text
                    @click="qrcodeDialog = false"
                    >
                    关闭
                    </v-btn>
                      <!-- <v-btn
                    color="orange darken-1"
                    text
                    @click="downsDebounce"
                    >
                    下载二维码
                    </v-btn> -->
                    </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-card>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'  
import {shopList,detailsList,imageUpload,shopsAdd,shopsDelete,shopsEdit,copyShopsList,copyShops,goodsShopList,addGoodsByList,addGoodsList,editPrice,qrcode,deleteGoods} from '@/api/quotation.js'
import {parentList,childList} from '@/api/goods.js'
export default {
    components:{
        QRCode,
    },
    data(){
        return{
            e1: 1,
            e2:1,
            valid:true,
            dialog:false,
            addDialog:false,
            messageError:false,
            dialogDelete:false,
            delGoodsDialog:false,
            updateDialog:false,
            addGoodsDialog:false,
            messageSuccess:false,
            copyDialog:false,
            copyShopsDialog:false,
            editGoodsDialog:false,
            qrcodeDialog:false,
            parentData:[],
            parentName:[],
            parenthashName:[],
            sonData:[],
            sonName:[],
            sonhashName:[],
            message:'',
            name:'',
            image:'',
            avatarInfo:null,
            avatarImage:'',
            images:[],
            contacts:'',
            editHash:'',
            // mobileRules:[ v => /^[1][3456789][0-9]{9}$/.test(v) || '',],
            mobilenumber:'',
            wechatnumber:'',
            fileInfo:null,
            shopsData:[],
            page:1,
            pageCount:10,
            pagetotal:1,
            copyShopsData:[],
            copyPage:1,
            hash:'',
            copyPageCount:10,
            copyPagetotal:1,
            addPage:1,
            addHash:'',
            addShopsData:[],
            addPageCount:10,
            addPagetotal:1,
            editPage:1,
            goodsHash:'',
            editGoodsData:[],
            editPageCount:10,
            editPagetotal:1,
            qrcodeh5url:'',
            qrcodebdminiappdouyin:'',
            qrcodebdminiapphuoshan:'',
            qrcodebdminiapppipixia:'',
            qrcodebdminiapptoutiao:'',
            qrcodewxminiapp:'',
            clientHeight:null,
            addEditPage:1,
            addEditPageCount:10,
            addEditPagetotal:1,
            addEditGoodsData:[],
            value:'',
            notice:[],
            parenthashList:[],
            sonNameList:[],
            categoryhashList:[],
            asd:[],
            categoryparent:[],
            category:[],
            editParentName:[],
            editParenthashName:[],
            editSonNameList:[],
            editSonhashName:[],
            categoryName:'',
            screenHeight:350
        }
    },
     computed:{
        headers () {
            return [
            {
                text: '名称',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: '联系人', sortable: false,value: 'contacts' },
             { text: '点赞数', sortable: false,align:'center',value: 'likes' },
            { text: '操作',sortable: false, value: 'actions' }
            ]
      },
      copyHeaders(){
        return [
            {
                text: '名称',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: '联系人',sortable: false, value: 'contacts' },
            { text: '操作', sortable: false,value: 'actions' }
        ]
      },
      addHeaders(){
        return [
            {
                    text: '商品名称',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text:'价格/单位',sortable: false,value:'sku'},
                {text:'规格',sortable: false,value:'specs'},
                {text:'图片',sortable: false,value:'image'},
                { text: '所属分类', sortable: false,value: 'category'},
                { text: '操作',sortable: false, value: 'actions' }
        ]
      },
      editHeaders(){
          return [
            {
                    text: '商品名称',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: '所属分类', sortable: false,value: 'category'},
                {text:'规格详情',sortable: false,value:'sku'},
                 { text: '操作', sortable: false,value: 'actions' }
        ]
      },
      addEditHeaders(){
           return [
            {
                    text: '商品名称',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: '所属分类', sortable: false,value: 'category'},
                {text:'规格详情',sortable: false,value:'sku'},
                 { text: '操作', sortable: false,value: 'actions' }
        ]
      }
    },
    watch:{
        page: { 
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                this.getCategoryList();
                // console.log(this.page);
            }
        },
        copyPage: { 
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                this.copyVersion();
                // console.log(this.page);
            }
        },
        addPage:{
             // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                this.addGoods();
                // console.log(this.page);
            }
        },
        editPage:{
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                // this.addGoodsConfirm();
                this.editGoods();
                // console.log(this.editPage);
            }
        },
        addEditPage:{
            deep:true,
            handler(){
                this.addGoodsConfirm();
                // console.log(this.addEditPage);
            }
        },
        screenHeight(val){
            this.screenHeight = val
        }
    },
    mounted(){
        this.getShopsList();
        this.$nextTick(()=>{
            this.screenHeight = document.body.clientHeight;
            window.onresize = () => {
                return (() => {
                    this.screenHeight = document.body.clientHeight-200;
                    // console.log(this.screenHeight);
                })();
            }
        })
        
    },
    methods:{
        mobileRule(value){
            if(value ===null){
                return false
            }
            if(value.length === 0){
                return "手机号不能为空";
            }
            else if(value.length > 0){
                if(value.length===11){
                var Reg = /^[1][3456789][0-9]{9}$/;
                if(!Reg.test(value)){
                    return "请正确填写手机号";
                }else{
                    return true
                }
                }
                else if(value.length>11){
                    return "请正确填写手机号";
                }else{
                return false
                }
            }
        },
        getShopsList(){
            shopList(this.page).then(res=>{
                // console.log(res);
                this.shopsData = res.data.data.list;
                this.pagetotal = res.data.data.pagetotal
            })

        },
        // 详情
        goToVersionList(hash){
            this.addHash = hash;
            this.e2 = 1;
            detailsList(hash).then(res=>{
                // console.log(res);
                this.name = res.data.data.name;
                this.contacts = res.data.data.contacts;
                this.avatarImage = res.data.data.avatar
                this.mobilenumber = res.data.data.mobilenumber;
                this.wechatnumber = res.data.data.wechatnumber;
                this.ads = res.data.data.ads;
                this.notice = res.data.data.notices
                this.editHash = res.data.data.hash
                this.images = this.ads.map(item=>{
                    return item.image
                })
                this.categoryparent = this.ads.map(item=>{
                    return item.categoryparent
                })
                this.category = this.ads.map(item=>{
                    return item.category
                })
                this.categoryhashList = this.ads.map(item=>{
                    return item.categoryhash
                })
                this.getParentsListData();
            })
        },
        // 添加
        createVersion(){
            this.e1 = 1;
            this.getParentsList();
        },
        // 添加图片
        uploadImage(){
            
            if(this.fileInfo){
                if(this.images.length === 3){
                    this.$nextTick(()=>{
                        this.message = "广告只能添加3条";
                        this.messageError = true;
                        this.fileInfo = null;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                    return false
                }
                var formData = new FormData();
                formData.append("file", this.fileInfo); 
                imageUpload('shop',formData).then(res=>{
                    this.image = res.data.data.image;
                    this.$nextTick(()=>{
                        this.images.push(this.image)
                        // console.log( this.images);
                        this.fileInfo = null
                         
                    })
                })
            }
        },
        // 添加头像
        uploadAvatar(){
            //  console.log(this.avatarInfo);
            if(this.avatarInfo){
                var formData = new FormData();
                formData.append("file", this.avatarInfo); 
                imageUpload('shop',formData).then(res=>{
                    this.avatarImage = res.data.data.image;
                    // this.$set(this.image,res.data.data.image)
                })
            }else{
                 this.avatarImage  = '';
            }
        },
        // 添加小店
        validateShops(){
            if(!this.name||!this.contacts||!this.mobilenumber||!this.wechatnumber||!this.images){
                this.$refs.form.validate()
                this.$nextTick(()=>{
                    this.message = "还有信息尚未填写";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false
            }
            const ads = [];
            for(let i = 0; i < this.images.length; i++){
                var data = {};
                data = {
                    categoryhash:this.categoryhashList[i],
                    image:this.images[i]
                }
                ads.push(data);
            }
            // console.log(ads);
            var params = {
                name:this.name,
                contacts:this.contacts,
                avatar:this.avatarImage,
                mobilenumber:this.mobilenumber,
                wechatnumber:this.wechatnumber,
                ads:ads,
                notices:this.notice
            }
            shopsAdd(params).then(res=>{
                // console.log(res);
                if(res.data.code===200){
                    this.addHash = res.data.data.hash
                    
                    // this.addDialog = false;
                    // this.addGoodsDialog = true;
                    this. e1 = 2;
                    this.addGoods(this.addHash);
                    this.reset()
                   
                }else{
                    this.$nextTick(()=>{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
        // 编辑小店
        commitEditShops(){
            if(!this.name||!this.contacts||!this.mobilenumber||!this.wechatnumber||!this.images){
                this.$refs.form.validate()
                this.$nextTick(()=>{
                    this.message = "还有信息尚未填写";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
              
                })
                return false
            }
            const ads = [];
            for(let i = 0; i < this.images.length; i++){
                var data = {};
                data = {
                    categoryhash:this.categoryhashList[i],
                    image:this.images[i]
                }
                ads.push(data);
            }
             var params = {
                name:this.name,
                hash:this.editHash,
                avatar:this.avatarImage,
                contacts:this.contacts,
                mobilenumber:this.mobilenumber,
                wechatnumber:this.wechatnumber,
                ads:ads,
                notices:this.notice
            }
            shopsEdit(params).then(res=>{
                if(res.data.code===200){
                    this.getShopsList();
                    this.addGoods(this.addHash);
                    this.e2 = 2;
                    this.reset()
                   
                }else{
                    this.$nextTick(()=>{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
        // 添加公告
        addNoticeData(){
            if(this.notice.length === 3){
                this.$nextTick(()=>{
                    this.message = "公告数最多为三条";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false
            }
            this.notice.push(this.value);
            this.value = '';
            
        },
        // 删除公告
        deleteValue(index){
           this.notice.splice(index, 1)
        },
        // 获取父类
        getParentsList(){
            parentList().then(res=>{
                // console.log(res);
                this.parentData = res.data.data.list
                this.parentName = this.parentData.map(item=>{
                    return item.name
                })
            })
        },
        // 编辑获取父类
        getParentsListData(){
            parentList().then(res=>{
                // console.log(res);
                this.parentData = res.data.data.list
                this.parentName = this.parentData.map(item=>{
                    return item.name
                })
                this.parenthashName =  this.categoryparent;

                // console.log(this.parenthashName);
                for(let i = 0; i<this.parenthashName.length; i++){
                    const parenthash = this.parentData.filter(item=>{
                        if(item.name === this. parenthashName[i]){
                            return item
                        }
                    }).map(item=>{
                        return item.hash
                    })
                    if(!this.parenthashList[i]){
                        this.parenthashList.push(parenthash[0]);
                    }else{
                        this.parenthashList[i] = parenthash[0];
                    }
                }
                // console.log(this.parenthashList);
                 for(let i = 0; i<this.parenthashList.length; i++){
                   childList(this.parenthashList[i]).then(res=>{
                        this.sonData = res.data.data.list
                        this.sonName = this.sonData.map(item=>{
                            return item.name
                        })
                        // this.sonNameList[i] = [...this.sonName]
                        this.$set( this.sonNameList,i,[...this.sonName])
                        // console.log(this.sonNameList);
                        this.$set( this.sonhashName,i,this.category[i])
                    })
                 }
            })
        },
        // 选择子类
        choseParent(pHash,index){
            this.parenthashName[index] = pHash;
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName[index]){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            if(!this.parenthashList[index]){
                this.parenthashList.push(parenthash[0]);
            }else{
                this.parenthashList[index] = parenthash[0];
            }
            // console.log(this.parenthashList);
            childList(this.parenthashList[index]).then(res=>{
                this.sonData = res.data.data.list
                // console.log(this.sonData);
                this.sonName = this.sonData.map(item=>{
                    return item.name
                })
                if(!this.sonNameList[index]){
                    this.sonNameList.push([...this.sonName])
                }else{
                    // this.sonNameList[index] = [...this.sonName]
                    this.$set( this.sonNameList,index,[...this.sonName])
                }
            })
            // console.log(this.sonNameList);
        },
        // 获取子hash
        sonhashNameList(val,index){
            // console.log(val,index);
            this.sonhashName[index] = val;
            const sonhash = this.sonData.filter(item=>{
                if(item.name === this.sonhashName[index]){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            // console.log(this.sonData);
            if(!sonhash[0]){
               this.editSonhashNameList(this.categoryparent[index],index,val);
            //    this.sonhashNameList(val,index)
            }else{
                if(!this.categoryhashList[index]){
                    this.categoryhashList.push(sonhash[0])
                }else{
                    this.categoryhashList[index] = sonhash[0]
                }
                // console.log(this.categoryhashList); 
            }
        },
        //编辑-获取子hash
        editSonhashNameList(pHash,index,val){
            this.parenthashName[index] = pHash;
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName[index]){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            if(!this.parenthashList[index]){
                this.parenthashList.push(parenthash[0]);
            }else{
                this.parenthashList[index] = parenthash[0];
            }
            // console.log(this.parenthashList);
            childList(this.parenthashList[index]).then(res=>{
                this.sonData = res.data.data.list
                // console.log(this.sonData);
                this.sonName = this.sonData.map(item=>{
                    return item.name
                })
                if(!this.sonNameList[index]){
                    this.sonNameList.push([...this.sonName])
                }else{
                    // this.sonNameList[index] = [...this.sonName]
                    this.$set( this.sonNameList,index,[...this.sonName])
                }
                // console.log(this.sonNameList);
                this.sonhashName[index] = val;
                const sonhash = this.sonData.filter(item=>{
                    if(item.name === this.sonhashName[index]){
                        return item
                    }
                }).map(item=>{
                    return item.hash
                })
                if(!this.categoryhashList[index]){
                    this.categoryhashList.push(sonhash[0])
                }else{
                    this.categoryhashList[index] = sonhash[0]
                }
                // console.log(this.categoryhashList); 
            })
            
        },
        // 编辑提交
        editInformation(){
             if(!this.name||!this.contacts||!this.mobilenumber||!this.wechatnumber||!this.images){
                this.$refs.form.validate()
                this.$nextTick(()=>{
                    this.message = "还有信息尚未填写";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false
            }
            const ads = [];
            for(let i = 0; i < this.images.length; i++){
                var data = {};
                data = {
                    categoryhash:this.categoryhashList[i],
                    image:this.images[i]
                }
                ads.push(data);
            }
             var params = {
                name:this.name,
                hash:this.editHash,
                avatar:this.avatarImage,
                contacts:this.contacts,
                mobilenumber:this.mobilenumber,
                wechatnumber:this.wechatnumber,
                ads:ads,
                notices:this.notice
            }
            // console.log(params);
            shopsEdit(params).then(res=>{
                if(res.data.code===200){
                    this.getShopsList();
                    this.updateDialog = false;
                    this.reset()
                }else{
                    this.$nextTick(()=>{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
            })
        },
        // 删除图片
        deleteKey(item,index){
            this.images.splice(index, 1);
            this.categoryhashList.splice(index, 1);
            this.parenthashList.splice(index, 1);
             this.parenthashName.splice(index, 1);
            this.sonhashName.splice(index, 1);
            this.sonNameList.splice(index, 1);
            // console.log( this.categoryhashList);
        },
        // 重置
        reset(){
            this.images = [];
            this.avatarImage = '';
            this.fileInfo = null;
            this.avatarInfo = null;
            this.value = '';
            this.parentData=[];
            this.parentName=[];
            this.parenthashName=[];
            this.sonData=[];
            this.sonName=[];
            this.sonhashName=[];
            this.notice=[];
            this.parenthashList=[];
            this.sonNameList=[];
            this.categoryhashList=[];
            this.getShopsList();
            this.$refs.form.reset();
        },
        // 删除
        deleteApplication(hash){
            this.deletHash = hash
            this.dialogDelete = true ;
        },
        deleteGood(hash){
            this.goodsHash = hash
            this.delGoodsDialog = true;
        },
        // 复制商店列表信息
        copyVersion(){
            this.copyDialog = true;
            copyShopsList(this.copyPage).then(res=>{
                // console.log(res);
                this.copyShopsData = res.data.data.list
                this.copyPagetotal = res.data.data.pagetotal
            })
        },
        copyDialogClose(){
            this.getShopsList();
        },
        copyList(hash){
            this.hash = hash;
            this.copyShopsDialog = true;
        },
        copyGoodsBtn(){
            var params = {
                hash:this.hash  
            }
            copyShops(params).then(res=>{
                // console.log(res);
                this.copyShopsDialog = false;
                this.copyDialog = false;
                this.getShopsList();
            })
        },
        closeDelete(){
             this.dialogDelete = false;
        },
        // 删除
        deleteItemConfirm(){
            var params = {
                hash:this.deletHash
            }
            shopsDelete(params).then(res=>{
                // console.log(res);
                this.dialogDelete = false;
                this.getShopsList()
            })
        },
        // 删除商品
        deleteGoodsItem(){
            var params = {
                shopgoodshash:this.goodsHash
            }
            deleteGoods(params).then(res=>{
                // console.log(res);
                this.delGoodsDialog = false;
                this.addGoodsConfirm();
                this.editGoods()
            })
        },
        // 添加商品
        addGoods(addHash){
            if(addHash){
                this.addHash = addHash;
            }
            this.e2 = 2
            goodsShopList(this.addHash,this.addPage).then(res=>{
                // console.log(res);
                this.addShopsData = res.data.data.list
                const data = this.addShopsData.map(item=>{
                    return item.sku
                })
                for(let i= 0; i<this.addShopsData.length;i++){
                    const image = data[i].map(item=>{
                        return item.image
                    })
                    const specs = data[i].map(item=>{
                        return item.specs
                    })
                    this.addShopsData[i]={
                        ... this.addShopsData[i],
                        image:image,
                        specs:specs,
                        hidden:false
                    }
                }
                this.addPagetotal = res.data.data.pagetotal
            })
        },
          // 隐藏详细信息
        hidden(hidden,index){
            // console.log(hidden,index);
            this.addShopsData[index].hidden = !hidden
            // console.log(this.goodsData[index].hidden);
        },
        addGood(hash){
            var params ={
                shophash:this.addHash,
                goodshash:hash
            }
            addGoodsByList(params).then(res=>{
                // console.log(res);
                this.$nextTick(()=>{
                    this.message = "添加成功";
                    this.messageSuccess = true;
                    setTimeout(()=>{
                        this.messageSuccess = false;
                    },2000)
                })
            })
        },
        addGoodsConfirm(){
            // console.log(this.addHash,this.addEditPage);
            addGoodsList(this.addHash,this.addEditPage).then(res=>{
                this.e1=3;
                this.addEditGoodsData = res.data.data.list
                for(let i = 0 ; i<this.addEditGoodsData.length;i++ ){
                    for(let j = 0;j<this.addEditGoodsData[i].sku.length;j++){
                        this.addEditGoodsData[i].sku[j].price = this.addEditGoodsData[i].sku[j].price/100
                    }
                }
                this.addEditPagetotal = res.data.data.pagetotal
                // console.log(this.addEditGoodsData);
            })
        },
        editGoods(hash){
            // console.log(hash);
            if(hash){
                this.addHash = hash;
            }
            addGoodsList( this.addHash,this.editPage).then(res=>{
                // console.log(res);
                this.updateDialog = true;
                this.e2 = 3;
                this.editGoodsData = res.data.data.list;
                
                for(let i = 0 ; i<this.editGoodsData.length;i++ ){
                    for(let j = 0;j<this.editGoodsData[i].sku.length;j++){
                        this.editGoodsData[i].sku[j].price = this.editGoodsData[i].sku[j].price/100
                    }
                }
                // console.log(this.editGoodsData);
                this.editPagetotal = res.data.data.pagetotal;
            })
        },
        // 修改价格
        changePrice(hash,price){
            var params = {
                shopgoodsspechash:hash,
                price:price*100
            }
            // console.log(hash,price);
            editPrice(params).then(res=>{
                // console.log(res);
            })
        },
        // 获取二维码
        getQrcode(hash){
            if(!hash){
                hash = this.addHash
            }
            this.qrcodeDialog = true;
            qrcode(hash).then(res=>{
                // console.log(res);
                this.qrcodeh5url = res.data.data.qrcodeh5url;
                this.qrcodebdminiappdouyin = res.data.data.qrcodebdminiappdouyin;
                this.qrcodebdminiapphuoshan = res.data.data.qrcodebdminiapphuoshan;
                this.qrcodebdminiapppipixia = res.data.data.qrcodebdminiapppipixia;
                this.qrcodebdminiapptoutiao = res.data.data.qrcodebdminiapptoutiao;
                this.qrcodewxminiapp = res.data.data.qrcodewxminiapp;
            })
        },
        // 下载二维码
        downsDebounce(item,name){
             //防抖
             if(this.timer){
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(()=>{
                console.log(item);
                this.downs(item,name);
            },300)
        },
        downs(item,name) {//下载图片地址和图片名
          var image = new Image();
          // 解决跨域 Canvas 污染问题
          image.setAttribute("crossOrigin", "anonymous");
          image.onload = function() {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "tpshop"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          };
        image.src = item;
      }
    }
}
</script>
<style scoped>
.content{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
}
.content-img{
    margin: 0 15px;
}
.sku-add-goods{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px dashed #ccc;
    height: 65px;
}
.sku-add-goods:last-child{
    border-bottom:none;
}
.price{
    border: 1px solid #ccc;
    width: 80px;
    border-radius: 3px;
    padding-left:5px ;
}
.content-head{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.stepper{
    position: relative;
}
.stepper-header{
  position: fixed;
  top: 5%;
  width: 80%;
  left:10%;
  z-index: 5;
  background-color: #fff;
}
.stepper-content{
    margin-top: 70px;
}
.card-actions{
    border-top:1px solid #ccc;
   
}
.qrcode{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

}
.qrcode>div{
    margin:18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sku{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* border-bottom:1px dashed #ccc; */
    height: 65px;
    overflow: hidden;
}
.sku:last-child{
    border-bottom:none;
}
.hidden{
    height: 55px;
    color:#f57c00;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .message{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  z-index: 999;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>