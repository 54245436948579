import axios from '@/libs/api.request'
//列表
export const shopList = (page) => {
    return axios.request({
        url: `/store/shop/list/${page}`,
        method: 'get'
    })
}

// 详情
export const detailsList = (hash) => {
    return axios.request({
        url: `/store/shop/item/${hash}`,
        method: 'get'
    })
}
// 图片
export const imageUpload = (type,file) => {
    return axios.request({
        url: `/upload/${type}/image`,
        headers: {
            "Content-Type": "multipart/form-data",
            'X-AUTH-TOKEN':window.sessionStorage.getItem('token'),
        },
        data:file,
        method: 'post'
    })
}
// 添加
export const shopsAdd= (data) => {
    return axios.request({
        url: '/store/shop/add',
        data,
        method: 'post'
    })
}
// 删除
export const shopsDelete= (data) => {
    return axios.request({
        url: '/store/shop/delete',
        data,
        method: 'post'
    })
}
// 修改
export const shopsEdit= (data) => {
    return axios.request({
        url: '/store/shop/edit',
        data,
        method: 'post'
    })
}
// 可复制接口
export const copyShopsList = (page) => {
    return axios.request({
        url: `/store/shop/copy/list/${page}`,
        method: 'get'
    })
}
// 复制
export const copyShops = (data) => {
    return axios.request({
        url: '/store/shop/copy',
        data,
        method: 'post'
    })
}
//已添加商品列表
export const addGoodsList = (shophash,page) => {
    return axios.request({
        url: `/store/shop/goods/list/${shophash}/${page}`,
        method: 'get'
    })
}
// 商品列表
// export const goodsList = (page) => {
//     return axios.request({
//         url: `/store/goods/list/${page}`,
//         method: 'get'
//     })
// }
export const goodsShopList = (shophash,page) => {
    return axios.request({
        url: `/store/goods/shop/list/${shophash}/${page}`,
        method: 'get'
    })
}
// 添加商品
export const addGoodsByList = (data) => {
    return axios.request({
        url: '/store/shop/goods/add',
        data,
        method: 'post'
    })
}

// // 修改价格
export const editPrice = (data) => {
    return axios.request({
        url: '/store/shop/goods/spec/edit',
        data,
        method: 'post'
    })
}
// 删除已添加商品
export const deleteGoods = (data) => {
    return axios.request({
        url: '/store/shop/goods/delete',
        data,
        method: 'post'
    })
}
// 二维码
export const qrcode = (page) => {
    return axios.request({
        url: `/store/shop/qrcode/${page}`,
        method: 'get'
    })
}
// /store/shop/goods/delete
// /store/shop/qrcode/{hash}
// /store/shop/goods/spec/edit
// /store/goods/list/{page}
// /store/shop/goods/list/{shophash}/{page}
// /store/shop/copy
// /store/shop/copy/list/{page}
// /store/shop/edit
// /store/shop/delete
// /store/shop/add
// /store/shop/list/{page}
// /store/shop/item/{hash}