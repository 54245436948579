<template>
    <div class="messages" style="margin:20px 20px;">
        <v-card class="mx-auto" width="63%" min-width="40%" flat
    > 
        <v-card-title
        style="border-bottom:1px solid #E0E0E0;height:54px;line-height:54px;padding:0 10px;margin:0 10px"
        >
        <span style="font-size:18px;">创建报价小店步骤</span>
        <v-spacer></v-spacer>
        </v-card-title>
        <v-timeline
            align-top
            :dense="$vuetify.breakpoint.smAndDown"
            style="padding:20px 50px;margin-top:20px"
        >
            <v-timeline-item
            v-for="(item, i) in itemsTree"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
            >
            <v-card
                :color="item.color"
                dark
                flat
                style="border:1px solid #fff"
            >
                <v-card-title style="display:flex;font-size:16px;height:45px;line-height:15px">
                {{item.title}}
                </v-card-title>
                <v-card-text class="white text--primary">
                <p style="padding-top:10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.text}}</p>
                <v-btn
                    :color="item.color"
                    class="mx-0"
                    @click="goTo(item.to)"
                    outlined
                >
                    进入
                </v-btn>
                </v-card-text>
            </v-card>
            </v-timeline-item>
        </v-timeline>
        </v-card>
        <div style="width:37%;">
            <v-card width="95%" style="margin-left:25px" flat
            >
                <v-card-text class="shop-text infop">
                    <div middle>
                        <!-- {{details.viptype}} -->
                       <!-- <img src="@/assets/vip/one_year.png" alt="" width="100px" ref="img"> -->
                       
                        <img v-if="details.viptype === 'one_month.png'" src="@/assets/vip/one_month.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'one_year.png'" src="@/assets/vip/one_year.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'five_year.png'" src="@/assets/vip/five_year.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'six_month.png'" src="@/assets/vip/six_month.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'three_month.png'" src="@/assets/vip/three_month.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'three_year.png'" src="@/assets/vip/three_year.png" alt="" width="100px" ref="img">
                        <img v-else-if="details.viptype === 'two_year.png'" src="@/assets/vip/two_year.png" alt="" width="100px" ref="img">
                    </div>
                    <div style="margin-left:25px">
                        <!-- <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        >
                        账号：<strong v-html="sub" style="margin-left:10px"></strong>
                        </v-col> -->
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="display:flex;"
                        >
                       商家名称
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="display:flex;font-weight:600"
                        >
                       {{details.storename}}
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                         style="display:flex;"
                        >
                        会员开始时间
                        </v-col>
                         <v-col
                        cols="12"
                        sm="6"
                        md="12"
                         style="display:flex;color:green"
                        >
                        {{details.vipstarttime}}
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="display:flex;"
                        >
                         会员结束时间
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        style="display:flex;color:red"
                        >
                         {{details.vipendtime}}
                        </v-col>
                    </div>
                    <!-- <div  middle>
                       <img src="@/assets/vip/1.png" alt="" width="100px">
                    </div> -->
                </v-card-text>
            </v-card>
            <div class="card-content" style="margin:15px 25px" v-if="iss==0">
                <v-col cols="12" sm="6" lg="12">
                    <v-card tile flat>
                        <v-card-text class="pa-0">
                        <v-row no-gutters>
                            <v-col class="pa-3 left-name" style="background-color:indigo">
                                <div class="white--text ">可添加子用户数</div>
                            </v-col>
                            <v-col class="pa-3">
                                <div class="layout justify-center align-center;">
                                    <!-- <v-icon v-show="Math.floor(details.subusernumber/100)!== 0" size="56px" color="indigo">mdi-numeric-{{Math.floor(details.subusernumber/100)}}</v-icon>
                                    <v-icon v-show="Math.floor(details.subusernumber/100)!== 0 || Math.floor(details.subusernumber%100/10)!==0" size="56px" color="indigo">mdi-numeric-{{Math.floor(details.subusernumber%100/10)}}</v-icon>
                                    <v-icon size="56px" color="indigo">mdi-numeric-{{details.subusernumber%100%10}}</v-icon> -->
                                   <div style="color:indigo;font-size:28px;font-weight:600;line-height:54px">{{details.subusernumber}}</div> 
                                </div>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="12">
                    <v-card tile flat>
                        <v-card-text class="pa-0">
                        <v-row no-gutters>
                            <v-col class="pa-3 left-name" style="background-color:#03A9F4">
                                <div class="white--text ">已添加子用户数</div>
                            </v-col>
                            <v-col class="pa-3">
                            <div class="layout justify-center align-center">
                                <!-- <v-icon size="56px" color="light-blue">mdi-numeric-{{details.subuseradded}}-circle-outline </v-icon> -->
                                <!-- <v-icon v-show="Math.floor(details.subuseradded/100)!== 0" size="56px" color="light-blue">mdi-numeric-{{Math.floor(details.subuseradded/100)}}</v-icon>
                                <v-icon v-show="Math.floor(details.subuseradded/100)!== 0 || Math.floor(details.subuseradded%100/10)!==0" size="56px" color="light-blue">mdi-numeric-{{Math.floor(details.subuseradded%100/10)}}</v-icon>
                                <v-icon size="56px" color="light-blue">mdi-numeric-{{details.subuseradded%100%10}}</v-icon> -->
                                <div style="color:#03A9F4;font-size:28px;font-weight:600;line-height:54px">{{details.subuseradded}}</div> 
                            </div>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="12">
                    <v-card tile flat>
                        <v-card-text class="pa-0">
                        <v-row no-gutters>
                            <v-col class="pa-3 left-name" style="background-color:orange">
                                <div class="white--text ">子用户总数</div>
                            </v-col>
                            <v-col class="pa-3">
                            <div class="layout justify-center align-center">
                                <!-- <v-icon size="56px" color="orange">mdi-numeric-{{details.subuserCount}}-circle-outline </v-icon> -->
                                <!-- <v-icon v-show="Math.floor(details.subuserCount/100)!== 0" size="56px"  color="orange">mdi-numeric-{{Math.floor(details.subuserCount/100)}}</v-icon>
                                <v-icon v-show="Math.floor(details.subuserCount/100)!== 0 || Math.floor(details.subuserCount%100/10)!==0" size="56px" color="orange">mdi-numeric-{{Math.floor(details.subuserCount%100/10)}}</v-icon>
                                <v-icon size="56px" color="orange">mdi-numeric-{{details.subuserCount%100%10}}</v-icon> -->
                                <div style="color:orange;font-size:28px;font-weight:600;line-height:54px">{{details.subuserCount}}</div> 
                            </div>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </div>
            <v-card width="95%" flat style="margin-left:25px;" v-if="iss==0">
                <v-card-title>
                    <span class="text-h8">添加小店</span>
                </v-card-title>
                <v-row  style="padding:20px">
                    <v-col>
                        <v-card
                            class="mx-auto style-content"
                            max-width="150"
                            flat
                        >
                            <v-card-title>
                                <span class="font-weight-light" style="font-size:16px">可添加小店数</span>
                            </v-card-title>
                            <v-card-text class="details-content">
                            <div style="color:orange">{{details.shopnumber}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card
                            class="mx-auto style-content"
                            max-width="150"
                            flat
                        >
                        <v-card-title>
                            <span class="font-weight-light" style="font-size:16px">已添加小店数</span>
                            </v-card-title>
                            <v-card-text class="details-content">
                            <div style="color:orange">{{details.shopadded}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-card width="95%" style="margin-left:25px;margin-top:25px" flat>
                <v-card-title >
                    <span class="text-h8">小店总数</span>
                </v-card-title>
                <v-row  style="padding:20px">
                    <v-col>
                        <v-card
                            class="mx-auto style-content"
                            max-width="150"
                            flat
                        >
                        <v-card-title>
                            <span class="font-weight-light" style="font-size:16px">我的小店总数</span>
                            </v-card-title>
                            <v-card-text class="details-content">
                            <div style="color:orange">{{details.shopcount}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col  v-if="iss==0">
                        <v-card
                            class="mx-auto style-content"
                            max-width="150"
                            flat
                        >
                            <v-card-title>
                            <span class="font-weight-light" style="font-size:16px">商家小店总数</span>
                            </v-card-title>
                            <v-card-text class="details-content">
                                <div style="color:orange">{{details.storeShopCount}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-card width="95%" style="margin-left:25px;margin-top:25px" flat >
                <v-card-title>
                    <span class="text-h8">商品总数</span>
                </v-card-title>
                <v-row  style="padding:20px">
                <v-col>
                    <v-card
                        class="mx-auto style-content"
                        max-width="150"
                        flat
                    >
                    <v-card-title>
                        <span class="font-weight-light"  style="font-size:16px">我的商品总数</span>
                        </v-card-title>
                        <v-card-text class="details-content">
                        <div style="color:orange">{{details.goodsCount}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col  v-if="iss==0">
                    <v-card
                        class="mx-auto style-content"
                        max-width="150"
                        flat
                    >
                    <v-card-title>
                        <span class="font-weight-light"  style="font-size:16px">商家商品总数</span>
                        </v-card-title>
                        <v-card-text class="details-content">
                        <div style="color:orange">{{details.stroeGoodsCount}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            </v-card>
        </div>
        <div style="height:45px"></div>
    </div>
</template>
<script>
import {storeDetails} from '@/api/user.js'
import {mapState} from 'vuex'
export default {
    data(){
        return{
            details:[],
            itemsTree: [
                {
                color: 'red lighten-2',
                icon: 'mdi-format-list-bulleted-square',
                title: '添加商品分类',
                to: '/goodsClassify',
                text: '分类限制两级，当添加的分类为一级分类时，上级分类则不用选择'
                },
                {
                color: 'purple darken-1',
                icon: 'mdi-gift',
                title: '添加商品',
                to: 'goods',
                text: '  商品可以新添加，也可以复制其它同企业主体用户已经添加的公开的产品'
                },
                {
                color: 'green lighten-1',
                icon: 'mdi-receipt',
                title: '添加报价小店',
                to: '/quotation',
                text: '  可创建或复制多个报价小店，展示不同的价格，小店之间无任何关联'
                },
                {
                color: 'indigo',
                icon: 'mdi-basket-plus-outline ',
                title: '  添加商品到报价小店',
                to: '/quotation',
                text: '  将已经添加的商品加入报价小店，可修改商品价格'
                },
                {
                color: 'lime darken-4',
                icon: 'mdi-qrcode',
                title: '生成报价小店二维码',
                to: '/quotation',
                text: '  生成报价小店二维码，可以直接使用手机扫描工具进入小店'
                },
                {
                color: 'brown',
                icon: 'mdi-share-variant ',
                title: '分享二维码给客户',
                to: '/quotation',
                text: '  二维码提供下载功能，下载二维码后分享给客户使用'
                },
            ],
        }
    },
    computed:{
    ...mapState({
      iss:state => state.user.iss,
      sub:state => state.user.sub,
    })
  },
    created(){
        this.$nextTick(()=>{
            this.getStoreDetails();
        })
    },
    methods:{
        getStoreDetails(){
            storeDetails().then(res=>{
                // console.log(res);
                this.details = res.data.data;
            })
        },
        goTo(path) {
        this.$router.push(path)
    },
    }
}
</script>
<style scoped>
.messages{
    display: flex;
    flex-wrap: wrap;
}
.col-sm-6,.col-sm-6,.col-md-6{
    padding:5px
}
.content{
    width: 80%;
    margin: 0 auto;
}
.subusernumber{
    width: 200px;
    height: 200px;
}
.style-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.shop-text{
    font-size: 16px;
}
.details-content{
     display: flex;
     padding-bottom: 40px;
    justify-content: center;
    font-size: 32px;
    color: orange;
}
.left-name{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-content{
    width:95%;
    margin-left:25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.header{
    width:140px;
    height: 140px;
    padding:8px;
    color:#f57c00;
    border:2px dashed #f57c00;
    border-radius: 50%;
}
.infop{
    display: flex;
    align-items: center;
}
</style>