import axios from '@/libs/api.request'

// 商品分类
//列表
export const categoryList = (page) => {
    return axios.request({
        url: `/store/goods/category/list/${page}`,
        method: 'get'
    })
}

//详情
export const categoryItem = (hash) => {
    return axios.request({
        url: `/store/goods/category/item/${hash}`,
        method: 'get'
    })
}
// 父类列表
export const parentList = () => {
    return axios.request({
        url: '/store/goods/category/parent/list',
        method: 'get'
    })
}
// 添加
export const addCategory = (data) => {
    return axios.request({
        url: '/store/goods/category/add',
        data,
        method: 'post'
    })
}
// 编辑
export const editCategory = (data) => {
    return axios.request({
        url: '/store/goods/category/edit',
        data,
        method: 'post'
    })
}
// 删除
export const delCategory = (data) => {
    return axios.request({
        url: '/store/goods/category/delete',
        data,
        method: 'post'
    })
}








// 商品
// 列表
export const goodsList = (page) => {
    return axios.request({
        url: `/store/goods/list/${page}`,
        method: 'get'
    })
}
export const goodsList1 = (keyword,page) => {
    return axios.request({
        url: `/store/goods/list/keyword/${keyword}/${page}`,
        method: 'get'
    })
}
export const goodsList2 = (categoryhash,page) => {
    return axios.request({
        url: `/store/goods/list/category/${categoryhash}/${page}`,
        method: 'get'
    })
}
export const goodsList3 = (categoryhash,keyword,page) => {
    return axios.request({
        url: `/store/goods/list/keyword/category/${categoryhash}/${keyword}/${page}`,
        method: 'get'
    })
}
//详情
export const goodsItem = (hash) => {
    return axios.request({
        url: `/store/goods/item/${hash}`,
        method: 'get'
    })
}
// 删除
export const deleteGoods = (data) => {
    return axios.request({
        url: '/store/goods/delete',
        data,
        method: 'post'
    })
}

// 图片
export const imageUpload = (type,file) => {
    return axios.request({
        url: `/upload/${type}/image`,
        headers: {
            "Content-Type": "multipart/form-data",
            'X-AUTH-TOKEN':window.sessionStorage.getItem('token'),
        },
        data:file,
        method: 'post'
    })
}
// 规格
export const specAdd= (data) => {
    return axios.request({
        url: '/store/goods/spec/add',
        data,
        method: 'post'
    })
}
export const specAddValue = (data) => {
    return axios.request({
        url: '/store/goods/spec/add/value',
        data,
        method: 'post'
    })
}
export const AddGoods = (data) => {
    return axios.request({
        url: '/store/goods/add',
        data,
        method: 'post'
    })
}
// 编辑
export const editGoods = (data) => {
    return axios.request({
        url: '/store/goods/edit',
        data,
        method: 'post'
    })
}
// 设置隐私
export const setIsprivate = (data) => {
    return axios.request({
        url: '/store/goods/isprivate',
        data,
        method: 'post'
    })
}
// 复制商品信息列表
export const copyGoodsList = (page) => {
    return axios.request({
        url: `/store/goods/copy/list/${page}`,
        method: 'get'
    })
}
// 复制商品
export const copyGoods = (data) => {
    return axios.request({
        url: '/store/goods/copy',
        data,
        method: 'post'
    })
}
// 子类列表
export const childList = (hash) => {
    return axios.request({
        url: `/store/goods/category/child/list/${hash}`,
        method: 'get'
    })
}
// /store/goods/copy
// /store/goods/category/child/list/{hash}
// /store/goods/copy/list/{page}
// /store/goods/isprivate
// /store/goods/edit
// /store/goods/delete
// /store/goods/add
// /store/goods/spec/add/value
// /store/goods/spec/add
// /upload/{type}/image
// /store/goods/item/{hash}
// /store/goods/list/{page}
// /store/goods/category/delete
// /store/goods/category/edit
// /store/goods/category/add
// /store/goods/parent/list
// /store/goods/category/list/{page}
// /store/goods/category/item/{hash}