<template>
    <div class="goodsClassify" style="margin:32px 20px">
        <v-card  style="height:100%;" flat>
            <v-row class="mb-6 pt-6 justify-start" style="margin-left:2.4%">
                <v-dialog
                v-model="addDialog"
                persistent
                max-width="600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn class="" color="primary" dark  v-bind="attrs"
                    v-on="on" @click="createVersion">
                    <v-icon
                    color="white"
                    >
                    mdi-playlist-plus  
                    </v-icon>
                    <span> 添加商品分类</span></v-btn>
                    </template>
                    <v-card>
                     <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                        {{message}}
                    </v-alert>
                    <v-card-title>
                    <span class="text-h5">添加商品分类</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                    <v-row justify="center" align-content="center">
                    <v-form
                    ref="form"
                    v-model="valid"
                    style="width:80%"
                    lazy-validation
                    >
                    
                        <v-select
                        :items="parentName"
                        v-model="parenthashName"
                        label="选择一级分类"
                        ></v-select>
                        <v-text-field 
                        v-model="secondName" 
                        :rules="[v => !!v || '分类名称不能为空']"
                         require label="添加分类名称">
                         </v-text-field>
                        <v-text-field
                        v-model="sort"
                        label="添加排序"
                        :type="'text'"
                        :rules="[v => !!v || '排序不能为空']"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        required
                        >
                        </v-text-field>
                        <v-file-input
                        v-model="avatarInfo"
                        required
                        show-size accept="image/png, image/jpeg, image/bmp"
                        @change="uploadAvatar"
                        label="点击上传该分类图片">
                        </v-file-input>
                        <div  class="content">
                            <img :src="avatarImage" alt="" height="100px">
                            <!-- <img v-if="avatarImage" src="@/assets/1.jpg" class="image" height="100px">  -->
                        </div>
                    </v-form>
                    </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="grey darken-1"
                    text
                    @click="addDialog = false;reset();"
                    >
                    关闭
                    </v-btn>
                    <v-btn
                    color="orange darken-1"
                    text
                    @click="validateCategory()"
                    >
                    确定
                    </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
                
            </v-row>
            <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden">
                <v-data-table
                fixed-header
                :headers="headers"
                :items="categoryData"
                :page.sync="page"
                @page-count="pageCount = $event"
                hide-default-footer
                no-data-text="暂无数据"
                style="width:95%;"
                >   
                    <template v-slot:[`item.name`]="{ item }">
                        <div v-if="item.parent">&nbsp;&nbsp;&nbsp; &nbsp;--&nbsp;&nbsp;{{item.name}}</div>
                        <div v-else>{{item.name}}</div>
                    </template>
                    <template v-slot:[`item.parent`]="{ item }">
                        {{item.parent}}
                    </template>
                    <template v-slot:[`item.image`]="{ item }">
                        <div style="height:100%;display:flex;align-items:center">
                            <img :src="item.image" alt=""  class="image">
                        </div>
                        <!-- <img src="@/assets/1.jpg" class="image"> -->
                        <!-- {{item.image}} -->
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <!-- {{item}} -->
                        <!-- <v-btn color="primary" dark @click="goToVersionList(item.hash,item.parent,item.name);updateDialog=true">编辑分类</v-btn> -->
                         <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                style="margin-right:20px"
                                @click="goToVersionList(item.hash,item.parent,item.name);updateDialog=true"
                                >
                                  mdi-pencil-box-multiple-outline 
                                </v-icon>
                            </template>
                            <span>编辑商品分类</span>
                        </v-tooltip>
                        <!-- <v-btn class="ml-2" color="error" dark @click="deleteApplication(item.hash)">删除</v-btn> -->
                         <v-tooltip top>
                            <template v-slot:activator="{ on,attrs }">
                                <v-icon
                                color="error"
                                v-on="on"
                                v-bind="attrs"
                                @click="deleteApplication(item.hash)"
                                >
                                 mdi-delete
                                </v-icon>
                            </template>
                            <span>删除该商品分类</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-row>
            <v-pagination
            v-model="page"
            :length="pagetotal"
            style="margin-top:20px"
            :total-visible="7"></v-pagination>
            <div style="height:20px"></div>
            <v-dialog
            v-model="updateDialog"
            persistent
            max-width="600px"
            >
                <v-card>
                <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                    {{message}}
                </v-alert>
                <v-card-title>
                <span class="text-h5">编辑商品分类</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                <v-row justify="center" align-content="center">
                <v-form
                ref="editForm"
                v-model="valid"
                style="width:80%"
                lazy-validation
                >
                
                    <v-select
                    :items="parentName"
                    v-model="parenthashName"
                    label="选择一级分类"
                    ></v-select>
                    <v-text-field 
                    v-model="secondName" 
                    :rules="[v => !!v || '分类名称不能为空']"
                    require label="编辑分类名称">
                    </v-text-field>
                    <v-text-field
                    v-model="sort"
                    label="添加排序"
                    :type="'text'"
                    :rules="[v => !!v || '排序不能为空']"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    required
                    >
                    </v-text-field>
                   <v-file-input
                    v-model="fileInfo"
                    required
                    show-size accept="image/png, image/jpeg, image/bmp"
                    @change="uploadImage"
                    label="点击上传该分类图片">
                    </v-file-input>
                    <div  class="content">
                        <img v-if="image" :src="image" class="image" height="100px"> 
                    </div>
                </v-form>
                </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="grey darken-1"
                text
                @click="updateDialog = false;reset1();"
                >
                关闭
                </v-btn>
                <v-btn
                color="orange darken-1"
                text
                @click="commitEditCategory()"
                >
                确定
                </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
                        {{message}}
                    </v-alert>
                    <v-card-title class="text-h8" style="display:flex;justify-content:center">你确定要删除该商品分类吗？</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" text @click="closeDelete">取消</v-btn>
                    <v-btn color="orange darken-1" text @click="deleteItemConfirm">确定</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>
import {categoryList,categoryItem,parentList,addCategory,imageUpload,editCategory,delCategory} from '@/api/goods.js'
export default {
    data(){
        return{
            valid: true,
            messageError:false,
            parenthashName:'',
            message:'',
            hash:'',
            sort:'',
            deletHash:'',
            fileInfo:null,
            image:'',
            avatarInfo:null,
            addDialog:false,
            updateDialog:false,
            dialogDelete:false,
            parentData:[],
            parentName:[],
            secondName:'',
            categoryData:[],
            page:1,
            pageCount:10,
            pagetotal:1,
            avatarImage:''
        }
    },
    computed:{
        headers () {
            return [
            {
                text: '分类名称',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: '父类', sortable: false,value: 'parent' },
            { text: '分类图片',sortable: false, value: 'image' },
            {text:'排序',sortable: false,value: 'sort'},
            { text: '操作', sortable: false,value: 'actions' }
       
            ]
      }
    },
    watch: {
        // 监视pagination属性的变化
        page: { 
            // deep为true，会监视pagination的属性及属性中的对象属性变化
            deep: true, 
            handler() {
                // 变化后的回调函数，这里我们再次调用getDataFromServer即可
                this.getCategoryList();
                // console.log(this.page);
            }
        },
        parenthashName(val){
            // console.log(val);
            this.parenthashName = val;
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            // console.log(parenthash[0]);
        }
    },
    mounted(){
        this.getCategoryList();
        // this.getCategoryItem();
    },
    methods:{
        // 获取列表
        getCategoryList(){
            categoryList(this.page).then(res=>{
               this.categoryData  = res.data.data.list;
            //    console.log(this.categoryData);
               this.pagetotal = res.data.data.pagetotal
            })
        },
        getCategoryItem(){
             categoryItem('9dCBBEW').then(res=>{
                // console.log(res);
            })
        },
        getParentList(){
            parentList().then(res=>{
                this.parentData = res.data.data.list
                this.parentName = this.parentData.map(item=>{
                    return item.name
                })
                this.parentName.unshift('无')
                // console.log(this.parentData);
            })
        },
        createVersion(){
            this.getParentList();
        },
        // 添加分类
        validateCategory(){
             if (!this.secondName) {
                this.$refs.form.validate();
                this.$nextTick(()=>{
                    this.message = "分类名称不能为空";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false;
            }
            this.commitCategory()
        },
        // 图片转换
        uploadImage(){
            // console.log(this.fileInfo);
            if(this.fileInfo){
                var formData = new FormData();
                formData.append("file", this.fileInfo); 
                imageUpload('category',formData).then(res=>{
                    this.image = res.data.data.image;
                })
            }else{
                this.image = '';
            }
        },
         uploadAvatar(){
            //  console.log(this.avatarInfo);
            if(this.avatarInfo){
                var formData = new FormData();
                formData.append("file", this.avatarInfo); 
                imageUpload('category',formData).then(res=>{
                    this.avatarImage = res.data.data.image;
                    // this.$set(this.image,res.data.data.image)
                })
            }else{
                 this.avatarImage = '';
            }
        },
        // 添加分类请求
        commitCategory(){
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
            if(!parenthash[0]){
                parenthash[0] = ''
            }
            this.$nextTick(()=>{
                var params = {
                    parenthash:parenthash[0],
                    name:this.secondName,
                    sort:parseInt(this.sort),
                    image:this.avatarImage
                }
                // console.log(params);
                addCategory(params).then(res=>{
                    if(res.data.code===200){
                        this.reset();
                        this.avatarImage = '';
                        this.addDialog =false;
                        this.getCategoryList();
                    }else{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    }
                })
            })
        },
        goToVersionList(hash,parenthashName,name){
           
            // console.log(hash);
            // console.log(hash,parenthashName);
            if(parenthashName){
                parentList().then(res=>{
                    this.parentData = res.data.data.list
                    this.parentName = this.parentData.map(item=>{
                        return item.name
                    })
                    this.parenthashName = parenthashName;
                    this.hash = hash;
                    
                    this.secondName = name;
                    this.parentName.unshift('无')
                    // console.log(this.parentData);
                })
            }else{
                 this.hash = hash;
                 this.secondName = name;
                 this.parentName.unshift('无')
            }
            categoryItem(hash).then(res=>{
                // console.log(res);
                this.image = res.data.data.image
                this.sort = res.data.data.sort+''

            })
        },
        commitEditCategory(){
             if (!this.secondName) {
                this.$refs.editForm.validate()
                this.$nextTick(()=>{
                    this.message = "分类名称不能为空";
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
                return false;
            }
            const parenthash = this.parentData.filter(item=>{
                if(item.name === this. parenthashName){
                    return item
                }
            }).map(item=>{
                return item.hash
            })
           
           if(!parenthash[0]){
               parenthash[0] = '';
           }

            this.$nextTick(()=>{
                var params = {
                    parenthash:parenthash[0],
                    hash:this.hash,
                    name:this.secondName,
                    sort:parseInt(this.sort),
                    image:this.image
                }
                // console.log(params);
                editCategory(params).then(res=>{
                    // console.log(res);
                    if(res.data.code===200){
                        this.updateDialog = false;
                        this.reset1();
                        this.getCategoryList();
                    }else{
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000) 
                    }
                    
                })
            })
        },
        deleteApplication(hash){
            this.deletHash = hash
            this.dialogDelete = true ;
        },
        closeDelete(){
             this.dialogDelete = false;
        },
        deleteItemConfirm(){
            var params = {
                hash:this.deletHash
            }
            delCategory(params).then(res=>{
                // console.log(res);
                if(res.data.code ===200){
                    this.dialogDelete = false;
                    this.getCategoryList();
                }else{
                    this.$nextTick(()=>{
                        // console.log(res);
                        this.message = res.data.message;
                        this.messageError = true;
                        setTimeout(()=>{
                            this.messageError = false;
                        },2000)
                    })
                }
                
            })
        },
        // 重置
        reset () {
            this.image = ''
            this.avatarImage = ''
            this.parentName=[];
            this.$refs.form.reset()
        },
        reset1(){
            this.image = ''
            this.avatarImage = ''
            this.parentName=[];
            this.$refs.editForm.reset()
        }
    }
}
</script>
<style scoped>
 .image{
     height: 100px;
 }
 .message{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>