<template>
  <v-card style="margin:32px 20px" flat>
      <v-alert v-show="messageError" type="error" :class="messageError?'message-subuser':''" dense>
          {{message}}
      </v-alert>
     <v-row class="mb-6 pt-6 justify-start" style="margin-left:2.4%">
       <v-dialog
        v-model="addDialog"
        persistent
        max-width="600px"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="" color="primary" dark  v-bind="attrs"
          v-on="on" @click="createVersion">
          <v-icon
          color="white"
          >
           mdi-account-plus
          </v-icon>
         <span>添加子用户</span></v-btn>
        </template>
        <v-card>
        <v-alert v-show="messageError" type="error" :class="messageError?'message-subuser':''" dense>
            {{message}}
        </v-alert>
        <v-card-title>
          
        <span class="text-h5">添加子用户</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          >
            <v-row justify="center" align-content="center">
              <v-col
                cols="12"
                sm="6"
                md="9"
                >
                <v-text-field v-model="mobile" class="mx-auto col-12" label="手机号" :rules="[mobileRule]" require />
              </v-col>
              <v-col
              cols="12"
              sm="6"
              md="5"
              >
              <v-text-field
                  v-model="validates"
                  class="my-4 mx-auto col-12"
                  :type="'text'"
                  label="输入验证码"
                  :rules="[v => !!v || '']"
                  require
                  oninput="value=value.replace(/[^\d]/g,'')"
              />
              </v-col>
              <v-col
              cols="12"
              sm="6"
              md="4"
              >
                <v-btn
                class="ma-2"
                outlined
                color="primary"
                v-if="codeShow"
                @click="getDynamic"
                >
                {{dynamic}}
                </v-btn>
                <v-btn
                class="ma-2"
                outlined
                color="primary"
                v-else
                @click="getDynamic"
                >
                {{count}}秒后重试
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="grey darken-1"
        text
        @click="addDialog = false;reset()"
        >
        关闭
        </v-btn>
        <v-btn
        color="orange darken-1"
        text
        @click="validateMoblie"
        >
        下一步
        </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="pa-auto ma-auto justify-center" style="overflow:hidden;">
      <v-data-table
        fixed-header
        :headers="headers"
        :items="appInfoList"
        :page.sync="page"
        @page-count="pageCount = $event"
        hide-default-footer
        no-data-text="暂无数据"
        style="width:95%;"
      >
        <template v-slot:[`item.status`]="{ item }">
          {{Number(item.status)|status}}
        </template>
        <template v-slot:[`item.createTime`]="{ item }">
         {{Number(item.createTime)|dateFormat}}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-btn color="primary" dark @click="goToVersionList(item.hash);updateDialog=true">修改密码</v-btn> -->
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="goToVersionList(item.hash);updateDialog=true"
            >
             mdi-pencil-box-multiple-outline 
            </v-icon>
            </template>
            <span>修改密码</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <!-- 设置密码 -->
    <v-dialog
        v-model="pwdDialog"
        persistent
        max-width="600px"
        >
        <v-card>
          <v-alert v-show="messageError" type="error" :class="messageError?'message-subuser':''" dense>
              {{message}}
          </v-alert>
          <v-card-title>
          <span class="text-h5">设置密码</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="formPwd"
                v-model="valid"
                lazy-validation
                >
                  <v-row justify="center" align-content="center">
                    <!-- <v-col
                      cols="12"
                      sm="6"
                      md="9"
                      >
                      <v-text-field v-model="mobile" class="mx-auto col-11" label="手机号" :rules="mobileRules" require />
                    </v-col> -->
                    <v-col
                    cols="12"
                    sm="6"
                    md="9"
                    >
                      <v-text-field
                              v-model="password"
                              class="my-4 mx-auto col-11"
                              :type="'password'"
                              :rules="[v => !!v || '密码不能为空']"
                              label="密码"
                              require
                          />
                      </v-col>
                      <v-col
                      cols="12"
                      sm="6"
                      md="9"
                      >
                        <v-text-field
                            v-model="confirm"
                            class="my-4 mx-auto col-11"
                            :type="'password'"
                            :rules="[v => !!v || '密码不能为空']"
                            label="确认密码"
                            require
                        />
                    </v-col>
                  </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="grey darken-1"
            text
            @click="pwdDialog = false;reset2()"
            >
            关闭
            </v-btn>
            <v-btn
            color="orange darken-1"
            text
            @click="validatePwdData"
            >
            确定
            </v-btn>
         </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- 修改密码 -->
    <v-dialog
        v-model="updateDialog"
        persistent
        max-width="600px"
        >
        <v-card>
        <v-alert v-show="messageError" type="error" :class="messageError?'message-subuser':''" dense>
            {{message}}
        </v-alert>
        <v-card-title>
        <span class="text-h5">修改该子用户密码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
            ref="editConfirm"
            v-model="valid"
            lazy-validation
            >
              <v-row justify="center" align-content="center">
                <v-col
                cols="12"
                sm="6"
                md="9"
                >
                  <v-text-field
                      v-model="password"
                      class="my-4 mx-auto col-11"
                      :type="'password'"
                      :rules="[v => !!v || '密码不能为空']"
                      label="密码"
                      require
                  />
                  </v-col>
                  <v-col
                  cols="12"
                  sm="6"
                  md="9"
                  >
                    <v-text-field
                        v-model="confirm"
                        class="my-4 mx-auto col-11"
                        :type="'password'"
                        :rules="[v => !!v || '密码不能为空']"
                        label="确认密码"
                        require
                    />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="grey darken-1"
        text
        @click="updateDialog = false;reset1()"
        >
        关闭
        </v-btn>
        <v-btn
        color="orange darken-1"
        text
        @click="updatePwdData()"
        >
        确定
        </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
     <v-pagination
      v-model="page"
      :length="pagetotal"
      style="margin-top:20px"
    ></v-pagination>
    <div style="height:20px"></div>
  </v-card>
</template>

<script>
import {getSubuserList,getSubmemberCode,smsSubmemberCode,validatePwd,updatePwd} from '@/api/user.js'
export default {
  data() {
    return {
      valid:true,
      messageSuccess:false,
      messageError:false,
      message:'',
      addDialog: false,
      pwdDialog:false,
      updateDialog:false,
      isLoading: true,
      updateDialog:false,
      count:60,
      totalDesserts:0,
      appInfoList: [],
      mobileRules:[ v => /^[1][3456789][0-9]{9}$/.test(v) || '请正确填写手机号',],
      mobile:'',
      validates:'',
      password:'',
      confirm:'',
      codeShow:true,
      dynamic:'获取验证码',
      hash:'',
      page:1,
      pageCount:10,
      pagetotal:2,
    }
  },
  watch: {
    // 监视pagination属性的变化
    page: { 
    // deep为true，会监视pagination的属性及属性中的对象属性变化
    deep: true, 
    handler() {
          // 变化后的回调函数，这里我们再次调用getDataFromServer即可
          this.getAppInfoList();
          // console.log(this.page);
      }
    }
 },
//   computed: mapState(['userInfo']),
 computed:{
     headers () {
        return [
          {
            text: '用户名',
            align: 'start',
            sortable: false,
            value: 'username',
          },
          { text: '状态',sortable: false, value: 'status' },
          { text: '操作',sortable: false, value: 'actions' }
        ]
      },
 },
  filters: {
      dateFormat:function(originVal) {
        const dt = new Date(originVal)

        const y = dt.getFullYear()
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')

        const hh = (dt.getHours() + '').padStart(2, '0')
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
      },
      status:function(originVal) {
        if(originVal){
          return '已禁用'
        }else{
          return '正常'
        }
      },
  },
  mounted() {
    this.getAppInfoList()
  },
  methods: {
    mobileRule(value){
        if(value ===null){
            return false
        }
        if(value.length === 0){
            return "手机号不能为空";
        }
        else if(value.length > 0){
            if(value.length===11){
            var Reg = /^[1][3456789][0-9]{9}$/;
            if(!Reg.test(value)){
                return "请正确填写手机号";
            }else{
                return true
            }
            }
            else if(value.length>11){
                return "请正确填写手机号";
            }else{
            return false
            }
        }
    },
     // 获取动态验证码
    getDynamic(){
        var Reg = /^[1][3456789][0-9]{9}$/;
        if(Reg.test(this.mobile)){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                var params={
                    phonenumber:this.mobile
                  }
                  getSubmemberCode(params).then(res=>{
                    if(res.data.code===200){
                      
                    }else{
                        this.$nextTick(()=>{
                          this.message = res.data.message;
                          this.messageError = true;
                          setTimeout(()=>{
                              this.messageError = false;
                          },2000)
                        })
                    }
                  })
                this.codeShow = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    }
                }, 1000)
            }
        }else{
           this.$nextTick(()=>{
            this.message = "请先填写手机号";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
        }
       
    },
    // 获取子用户列表
    getAppInfoList() {
      
      getSubuserList(this.page).then(res=>{
        // console.log(res);
        this.appInfoList = res.data.data.list;
        this.pagetotal = res.data.data.pagetotal
      })
    },
    goToVersionList(hash) {
        this.hash = hash
    },
    // 新建用户
     createVersion() {
       
     },
     //设置密码
     validateMoblie(){
       if (!this.mobile || !this.validates) {
         this.$refs.form.validate()
         this.$nextTick(()=>{
            this.message = "手机号与验证码不能为空";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
          return false;
        }
        var params={
          phonenumber:this.mobile,
          verifycode:this.validates
        }
        smsSubmemberCode(params).then(res=>{
            // console.log(res);
          if(res.data.code===200){
                 this.addDialog = false;
                 this.pwdDialog = true;
          }else{
              this.$nextTick(()=>{
                this.message = res.data.message;
                this.messageError = true;
                setTimeout(()=>{
                    this.messageError = false;
                },2000)
             })
             
          }
        })
     },
     validatePwdData(){
       if (!this.password || !this.confirm) {
         this.$refs.formPwd.validate()
         this.$nextTick(()=>{
            this.message = "密码与确认密码不能为空";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
          return false;
        }
       var params = {
            verifycode:this.validates,
            phonenumber:this.mobile,
            password:this.password,
            confirm:this.confirm
        }
        validatePwd(params).then(res=>{
            if(res.data.code===200){
              this.pwdDialog = false;
              this.password = '';
              this.confirm = '';
              this.reset2()
              this.getAppInfoList();
            }else{
                 this.$nextTick(()=>{
                    this.message = res.data.message;
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
            }
        })
     },
     updatePwdData(){
       if(!this.password||!this.confirm){
         this.$refs.editConfirm.validate()
         this.$nextTick(()=>{
            this.message = "请确认是否填写";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
         return false
       }
       var params = {
            hash:this.hash,
            password:this.password,
            confirm:this.confirm
        }
       updatePwd(params).then(res=>{
         if(res.data.code===200){
           this.updateDialog = false;
           this.$nextTick(()=>{
              this.message = "修改成功";
              this.messageSuccess = true;
              setTimeout(()=>{
                  this.messageSuccess = false;
              },2000)
            })
          this.password = '';
          this.confirm = '';
          this.reset1();
        }else{
            this.$nextTick(()=>{
              this.message = res.data.message;
              this.messageError = true;
              setTimeout(()=>{
                  this.messageError = false;
              },2000)
            })
          }
       })
     },
     reset(){
       this.mobile='';
       this.validates='';
       this.password = '';
       this.confirm = ''
       this.$refs.form.reset();
     },
     reset1(){
        this.mobile='';
        this.validates='';
        this.password = '';
        this.confirm = ''
        this.$refs.editConfirm.reset();
     },
      reset2(){
        this.mobile='';
        this.validates='';
        this.password = '';
        this.confirm = ''
        this.$refs.formPwd.reset();
     },
     changePage(){
      //  console.log(this.page);
     }

  }
}
</script>

<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: #999;
    font-size: 16px;
    font-weight: 400;
}
.theme--light.v-data-table {
    background-color: #FFFFFF;
    color: #333;
}
.message-subuser{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  z-index: 999;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>
